<template>
  <div class="titleTop">
    <div class="titleTopLeft">
      <router-link to="/index">
        <div class="titleTopLeftImg">
          <!-- <img src="../assets/img/mzlogo.png" alt="" /> -->
            <img v-if="orgimgkeyPathData2" :src=" orgimgkeyPathData2.orgimgkeyPath2" alt="" />
            <img v-else src="../assets/img/logo.png" alt="" />

        </div>
      </router-link>

      <div class="tabBox">
        <el-tabs v-model="activeName" @tab-click="handleClickTop">
          <el-tab-pane v-if="myUserInfo.isPharmacist != 1" label="患者病历" name="first"></el-tab-pane>
          <el-tab-pane v-if="myUserInfo.isPharmacist != 1" label="患者列表" name="second"></el-tab-pane>
          <el-tab-pane label="处方管理" name="fourth"></el-tab-pane>
          <el-tab-pane v-if="myUserInfo.isPharmacist != 1" label="订单管理" name="five"></el-tab-pane>
          <el-tab-pane v-if="myUserInfo.isPharmacist != 1" label="转诊管理" name="six"></el-tab-pane>
          <el-tab-pane v-if="myUserInfo.isPharmacist != 1" label="个人常用处方" name="third"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="titleTopRight">
      <div style="cursor: pointer;" @click="golockScreen">
        <i class="el-icon-lock"></i>锁屏
      </div>
      <router-link to="/index">
        <div><i class="el-icon-discount"></i>首页</div>
      </router-link>
      <!-- <div><i class="el-icon-s-opportunity"></i>相关教程</div> -->
      <router-link to="/personal/index">
        <div>个人中心</div>
      </router-link>
    </div>
    <!-- 锁屏 -->
    <el-dialog
      :visible.sync="isLockScreen"
      :close-on-click-modal="false"
      :show-close="false"
      :center="true"
      :fullscreen="true"
      class="dialogTrue"
      >
        <div class="flex-l-c" style="width:20%;margin:0 auto;">
          <div class="flex-l-c" style="padding-top:30%">
            <img v-if="userhead == '' || userhead == null" src="@/assets/img/head_img.png" alt="" />
            <img v-else :src="Services.Authorization + userhead" alt="" />
            <span style="font-size: 30px;color: #000000;margin:20px 0">{{userName}}</span>
          </div>
          <el-input placeholder="请输入登录密码" v-model="isLockScreenValue" show-password style="border: 2px solid #000000;"></el-input>
          <el-button type="info" @click="sureLockScreen" style="margin-top:40px;background: #000000;">确认解锁</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { patientMain } from "../components/patienTmanagement/patienTmanagement";
export default {
  props: {
    activeNameCheck: String,
  },
  data() {
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    return {
      activeName: "1",
      orgimgkeyPathData2:JSON.parse(localStorage.getItem('orgimgData2')) ? JSON.parse(localStorage.getItem('orgimgData2')) : '',
      myUserInfo:JSON.parse(sessionStorage.getItem('userInfo')),
      isLockScreenValue:'',
      isLockScreen:false,
      userName: this.$store.state.localData.userInfo.name,
      userhead:  this.$store.state.localData.userInfo.photo,
      userId:this.$store.state.localData.userInfo.id,
      patientDomain: patient,
    };
  },
  created() {
    this.activeName = this.activeNameCheck;
  },
  methods: {
    golockScreen(){
        this.isLockScreenValue= ''
     this.isLockScreen = true
    },
    sureLockScreen(){
    // this.isLockScreen = false
      // this.isLockScreenValue = ''
      var _this = this
      let params = {}
      params.id = _this.userId
      params.password = _this.isLockScreenValue
      _this.patientDomain.GetIsLockScreen(params,
        function (data) {
          _this.isLockScreen = false
          _this.$message({
            message: "已解锁",
            type: "success",
          });
        },
        function (error) {
        _this.$message({
            message: "密码错误",
            type: "error",
          });
        })
      console.log(this.isLockScreenValue)
    },
    handleClickTop(e, e1) {
      if (e.label == "患者病历") {
        this.$router.push({
          name: "interrogationIndex",
        });
      }
      if (e.label == "患者列表") {
        this.$router.push({
          name: "intPatientList",
        });
      }
      if (e.label == "个人常用处方") {
        this.$router.push({
          name: "preintPatientList",
        });
      }
      if (e.label == "处方管理") {
        if (this.myUserInfo.isPharmacist == 1) {
          this.$router.push({
            name: "drugPremangePatientList",
          });
        } else {
          this.$router.push({
            name: "PremangePatientList",
          });
        }
   
      }
      if (e.label == "订单管理") {
        this.$router.push({
          name: "orderList",
        });
      }
      if (e.label == "转诊管理") {
        this.$router.push({
          name: "referralAndmedical",
        });
      }
    },
  },
};
</script>
<style scoped>
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
/* .titleTopLeftImg {
  width: 123px;
  height: 24px;
} */
.titleTopLeftImg  {
  margin-left: 10px;
  /* width: auto;
  height: 35px; */
}
.titleTopLeftImg img {
  width: auto;
  height: 35px;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #01C2AC;
}
.titleTopRight div {
  margin-left: 10px;
  color: #01C2AC;
}
::v-deep .el-tabs__active-bar{
  width: 0 !important;
}
.dialogTrue ::v-deep .el-dialog.is-fullscreen {
  background-image: url(../assets/img/wlbg.jpg);
  background-size: cover;
}
/* .lockScreenBox {
  background-image: url('../../assets/img/wlbg.jpg');
  background-size: cover;
} */
  .flex-l-c {
  display: flex;
  align-items: center;
  flex-direction: column;
  }
  .flex-l-c img {
    width: 107px;
    height: 100px;
    border-radius: 50%;
  }
</style>
