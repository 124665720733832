<template>
  <div class="inteBox">
    <intNavheader></intNavheader>
    <div class="titleTopLine"></div>
    <el-dialog title="提示" :visible.sync="printdialogVisibleQT" width="60%">
      <div ref="printQT" class="PrintBox">
        <!-- 检查治疗项 -->
        <div v-if="patientDetail.mzCheckItems.length > 0 || patientDetail.mzOtherCharges.length > 0">
          <div class="PrintBoxTitle">门诊处方笺</div>
          <div class="PrintBoxTitle1">
            <div>就诊日期：{{ PrescriptionDetail.addTime | timefilters }}</div>
            <div>病历号:{{ PrescriptionDetail.diagnoseNumber}}</div>
          </div>
          <div class="PrintBoxTitle2">
            <div>姓名：{{ patientDetailInfo.name }}</div>
            <div>手机号:{{ patientDetailInfo.phone }}</div>
            <div>性别:{{ patientDetailInfo.sex ? "男" : "女" }}</div>
            <div>年龄:{{ patientDetailInfo.age }}</div>
            <div>部门:{{ PrescriptionDetail.deparment }}</div>
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>
            <div>诊断: {{ patientDetailAll.diseaseDiagnose }}</div>
          </div>
          <div class="PrintBoxTitleLineD"></div>
          <div style="font-size: 32px; margin: 10px 0">R</div>
          <div style="min-height: 400px">
            <div v-if="patientDetail.mzCheckItems.length > 0">
              <div v-for="(item, index) in patientDetail.mzCheckItems" :key="index">
                <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                  <div style="color: #999999">{{ item.itemName }}:</div>
                  <div style="color: #999999; margin-left: 10px">{{ item.tradingPrice }}</div>
                </div>
              </div>
            </div>
            <div v-if="patientDetail.mzOtherCharges.length > 0">
              <div v-for="(item, index) in patientDetail.mzOtherCharges" :key="index">
                <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                  <div style="color: #999999">{{ item.chargeName }}:</div>
                  <div style="color: #999999; margin-left: 10px">{{ item.money }}</div>
                </div>
              </div>
            </div>
          </div>
          <div>合计费用:{{ totalPrice }}</div>
          <div>医嘱:{{ patientDetail.mzPrescriptionOut.doctorAdvice ? patientDetail.mzPrescriptionOut.doctorAdvice : "--" }}</div>
          <div class="PrintBoxTitleLine"></div>
          <div class="PrintBoxTitle2">
            <div>医生：{{ PrescriptionDetail.doctorName }}{{'【' + PrescriptionDetail.doctorPhone  +'】'}}</div>
            <!-- <div>
              药剂师：
              <img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImgPharmacist" style="width: 75px; height: 25px" />
            </div> -->
            <!-- {{ userInfo.organizationName }} -->
          </div>
          <div class="flex-l">
            <div >
              审核医生：
              <!-- <img :src="patientDetail.mzPrescriptionOut.auditSignImg" style="width: 300px; height: 100px" fit="cover " /> -->
              <img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 75px; height: 25px" />
              <!-- <el-image :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 150px; height: 50px" fit="cover "> </el-image> -->
            </div>

          </div>
          <div>
            <!-- <div>开具日期：{{ patientDetail.mzPrescriptionOut.auditTime | timefilters }}</div> -->
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>注意处方有效期3天、擅自下载打印无效</div>
          <div style="page-break-after: always"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="printdialogVisibleSY" width="60%">
      <div ref="printSY" class="PrintBox">
        <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0">
          <div class="PrintBoxTitle">门诊处方笺</div>
          <div class="PrintBoxTitle1">
            <div>就诊日期：{{ PrescriptionDetail.addTime | timefilters }}</div>
            <div>病历号:</div>
          </div>
          <div class="PrintBoxTitle2">
            <div>姓名：{{ patientDetailInfo.name }}</div>
            <div>手机号:{{ patientDetailInfo.phone }}</div>
            <div>性别:{{ patientDetailInfo.sex ? "男" : "女" }}</div>
            <div>年龄:{{ patientDetailInfo.age }}</div>
            <div>部门:{{ PrescriptionDetail.deparment }}</div>
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>
            <div>诊断: {{ patientDetailAll.diseaseDiagnose }}</div>
          </div>
          <div class="PrintBoxTitleLineD"></div>
          <div style="font-size: 32px; margin: 10px 0">R</div>
          <!-- 输液方剂 -->
          <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0" style="min-height: 420px">
            <div v-for="(item, index) in patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup" :key="index">
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div style="color: #999999">{{ item.prescriptionName }}</div>
              </div>
              <div v-for="(item1, index1) in item.mzPrescriptionGroups" :key="index1">
                <div style="margin-left: 15px; margin-top: 5px">
                  <div></div>
                  <div>
                    <span>组{{ index1 + 1 }} 用法：{{ item1.usage }};用药频率：{{ item1.frequency }};滴速 {{ item1.drippingSpeed }}{{ item1.drippingSpeedUnit }};起始日期：{{ item1.startTime ?  item1.startTime.slice(0, 10) : '' }};连续天数：{{ item1.days }}</span>
                  </div>
                </div>
                <div v-for="(item2, index2) in item1.mzPrescriptionDetailSY" :key="index2" style="margin-left: 15px; margin-top: 5px">
                  <div></div>
                  <div>
                    <span> 名称：{{ item2.drugOrgName }};皮试：{{ item2.st }};单次剂量：{{ item2.dosage }}{{ item2.dosageUnit }};开药量：{{ item2.defaultDosage }}{{ item2.defaultDosageUnit }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>医嘱:{{ patientDetail.mzPrescriptionOut.doctorAdvice ? patientDetail.mzPrescriptionOut.doctorAdvice : "--" }}</div>
          <div class="PrintBoxTitleLine"></div>
          <div class="PrintBoxTitle2">
            <div>医生：{{ PrescriptionDetail.doctorName }}{{'【' + PrescriptionDetail.doctorPhone  +'】'}}</div>
                  <!-- <div>
              药剂师：
              <img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImgPharmacist" style="width: 75px; height: 25px" />
            </div> -->
            <!-- {{ userInfo.organizationName }} -->
          </div>
          <div class="flex-l">
            <div>审核医生：  <img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 75px; height: 25px" /></div>
      
          </div>
          <div>
            <!-- <div>开具日期：{{ patientDetail.mzPrescriptionOut.auditTime | timefilters }}</div> -->
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>注意处方有效期3天、擅自下载打印无效</div>
          <div style="page-break-after: always"></div>
        </div>
      </div>
      <!-- 输液方剂 -->
    </el-dialog>
    <el-dialog title="提示" :visible.sync="printdialogVisibleCY" width="60%">
      <div ref="printCY" class="PrintBox">
        <!-- 成药方剂 -->
        <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionCY.length > 0">
          <div class="PrintBoxTitle">门诊处方笺</div>
          <div class="PrintBoxTitle1">
            <div>就诊日期：{{ PrescriptionDetail.addTime | timefilters }}</div>
            <div>病历号:{{ PrescriptionDetail.diagnoseNumber}}</div>
          </div>
          <div class="PrintBoxTitle2">
            <div>姓名：{{ patientDetailInfo.name }}</div>
            <div>手机号:{{ patientDetailInfo.phone }}</div>
            <div>性别:{{ patientDetailInfo.sex ? "男" : "女" }}</div>
            <div>年龄:{{ patientDetailInfo.age }}</div>
            <div>部门:{{ PrescriptionDetail.deparment }}</div>
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>
            <div>诊断: {{ patientDetailAll.diseaseDiagnose }}</div>
          </div>
          <div class="PrintBoxTitleLineD"></div>
          <div style="font-size: 32px; margin: 10px 0">R</div>

          <!-- 成药方剂 -->
          <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionCY.length > 0" style="min-height: 420px">
            <div v-for="(item, index) in patientDetail.mzPrescriptionOut.mzPrescriptionCY" :key="index">
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div style="color: #999999">{{ item.prescriptionName }}</div>
              </div>
              <!-- <div style="margin-left: 15px; margin-top: 5px" v-for="(item1, index1) in item.mzPrescriptionDetail" :key="index1"> -->
                <el-table :data="item.mzPrescriptionDetail">
                  <el-table-column prop="drugOrgName" align="center" label="药品名称">
                    <template slot-scope="scope">
                      {{ scope.row.drugOrgName }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="groupNumber" align="center" label="组号">
                    <template slot-scope="scope">
                      {{ scope.row.groupNumber }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="usage" align="center" label="用法">
                    <template slot-scope="scope">
                      {{ scope.row.usage }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="frequency" align="center" label="用药频率">
                    <template slot-scope="scope">
                      {{ scope.row.frequency }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosage" align="center" label="单次剂量">
                    <template slot-scope="scope">
                      {{ scope.row.dosage + scope.row.dosageUnit }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="defaultDosage" align="center" label="开药量">
                    <template slot-scope="scope">
                      {{ scope.row.defaultDosage + scope.row.defaultDosageUnit }}
                    </template>
                  </el-table-column>
                </el-table>
              <!-- </div> -->
            </div>
          </div>
          <div>医嘱:{{ patientDetail.mzPrescriptionOut.doctorAdvice ? patientDetail.mzPrescriptionOut.doctorAdvice : "--" }}</div>
          <div class="PrintBoxTitleLine"></div>
          <div class="PrintBoxTitle2">
            <div>医生：{{ PrescriptionDetail.doctorName }}{{'【' + PrescriptionDetail.doctorPhone  +'】'}}</div>
            <!-- <div>
              药剂师：
              <img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImgPharmacist" style="width: 75px; height: 25px" />
            </div> -->
            <!-- {{ userInfo.organizationName }} -->
          </div>
          <div class="flex-l">
            <div>审核医生：  <img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 75px; height: 25px" /></div>
  
          </div>
          <div>
            <!-- <div>开具日期：{{ patientDetail.mzPrescriptionOut.auditTime | timefilters }}</div> -->
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>注意处方有效期3天、擅自下载打印无效</div>
          <div style="page-break-after: always"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="printdialogVisible" width="60%">
      <!-- 中哟方剂 -->
      <div ref="print" class="PrintBox">
        <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionYP.length > 0">
          <div class="PrintBoxTitle">门诊处方笺</div>
          <div class="PrintBoxTitle1">
            <div>就诊日期：{{ PrescriptionDetail.addTime | timefilters }}</div>
            <div>病历号:{{ PrescriptionDetail.diagnoseNumber}}</div>
          </div>
          <div class="PrintBoxTitle2">
            <div>姓名：{{ patientDetailInfo.name }}</div>
            <div>手机号:{{ patientDetailInfo.phone }}</div>
            <div>性别:{{ patientDetailInfo.sex ? "男" : "女" }}</div>
            <div>年龄:{{ patientDetailInfo.age }}</div>
            <div>部门:{{ PrescriptionDetail.deparment }}</div>
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>
            <div>诊断: {{ patientDetailAll.diseaseDiagnose }}</div>
          </div>
          <div class="PrintBoxTitleLineD"></div>
          <div style="font-size: 32px; margin: 10px 0">R</div>
          <!-- 中哟方剂 -->
          <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionYP.length > 0" style="min-height: 420px;position: relative;">
            <div v-for="(item, index) in patientDetail.mzPrescriptionOut.mzPrescriptionYP" :key="index">
              <div class="flex-l" style="margin-left:15px; margin-top: 5px">
                <div style="color: #999999">{{ item.prescriptionName }}</div>
              </div>
              <div class="flex-l" style="margin-left:15px; margin-top: 5px; flex-wrap: wrap">
                  <div  v-for="(item1, index1) in item.mzPrescriptionDetail" :key="index1" class="ZydrugOrgNameBox1">
                  <div class="ZydrugOrgNameBox2">
                    <span style="margin-right:10px">{{ item1.drugOrgName }}<span style="margin-left:10px">{{ item1.weight }}g</span>{{ item1.remake ? item1.remake :'' }}{{ item1.decoct ? item1.decoct :''}}</span>
                  </div>
                </div>
              </div>
              <div class="ZydrugBtoBox"></div>
              <div class="ZydrugLineBox">
                <div style="margin-left:15px;display: flex; align-items: center">
                  <div>剂数：{{ item.dosage }}</div>
                  <div style="margin-left: 10px">每日剂量: {{ item.dosageNumber }}</div>
                </div>
                <div style="margin-left:15px;display: flex; align-items: center">
                  <div>用药频率:{{ item.frequency }}</div>
                  <div style="margin-left: 10px">用法: {{ item.usage }}</div>
                </div>
                <div style="margin-left:15px;">
                  <div>服用要求：{{ item.request }}</div>
                </div>

              </div>

            </div>
          </div>
          <div>医嘱:{{ patientDetail.mzPrescriptionOut.doctorAdvice ? patientDetail.mzPrescriptionOut.doctorAdvice : "--" }}</div>
          <div class="PrintBoxTitleLine"></div>
          <div class="PrintBoxTitle2">
            <div>医生：{{ PrescriptionDetail.doctorName }}{{'【' + PrescriptionDetail.doctorPhone  +'】'}}</div>
            <!-- <div>
              药剂师：
              <img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImgPharmacist" style="width: 75px; height: 25px" />
            </div> -->
            <!-- {{ userInfo.organizationName }} -->
          </div>
          <div class="flex-l">
            <div>审核医生：<img :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 75px; height: 25px" /></div>
  
          </div>
          <div>
            <!-- <div>开具日期：{{ patientDetail.mzPrescriptionOut.auditTime | timefilters }}</div> -->
          </div>
          <div class="PrintBoxTitleLine"></div>
          <div>注意处方有效期3天、擅自下载打印无效</div>
          <div style="page-break-after: always"></div>
        </div>
      </div>
    </el-dialog>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核</div> -->
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>
      </div>
      <div class="titleTopCenterCenter" style="padding-bottom: 20px; height: calc(100vh - 110px); overflow-y: auto">
        <div class="titleTopCenterCenterTop titleTopCenterCenterTopLine">
          <div class="flex-l-b">
            <el-page-header @back="goBack" content="病历详情"> </el-page-header>
            <!-- <div class="Content1Title"><i class="el-icon-back" @click="goBack"></i>病历详情</div> -->
            <div class="flex-l">
              <!-- <el-button v-if="patientDetail.mzPrescriptionOut.mzPrescriptionYP.length > 0" @click="goPrint" size="mini" type="primary">打印方剂处方</el-button>
              <el-button v-if="patientDetail.mzPrescriptionOut.mzPrescriptionCY.length > 0" @click="goPrintCY" size="mini" type="primary">打印成药处方</el-button>
              <el-button v-if="patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0" @click="goPrintSY" size="mini" type="primary">打印输液处方</el-button>
              <el-button v-if="patientDetail.mzCheckItems.length > 0 || patientDetail.mzOtherCharges.length > 0" @click="goPrintQT" size="mini" type="primary">打印其他费用</el-button> -->
            </div>
          </div>
          <div class="topDetailBox" style="margin-top: 20px; position: relative;color: #333333;">
            <div style="font-weight: bold;font-size: 20px;">
              <span>诊断：</span>
              <span>{{ patientDetailAll.diseaseDiagnose }}</span>
            </div>
            <div class="noflex-l-s1" style="margin-top: 20px;">
              <div style="padding-right: 25px">
                <span>就诊时间：</span><span>{{ patientDetail.mzPrescriptionOut.addTime | timefilters }}</span>
              </div>
              <div style="padding-right: 25px">
                <span>机构：</span><span>{{ patientDetail.mzPrescriptionOut.organizationName }}</span>
              </div>
              <div style="padding-right: 25px">
                <span>问诊医生：</span><span>{{ patientDetail.mzPrescriptionOut.userName }}{{"【" + patientDetail.mzPrescriptionOut.userPhone+  "】"}}</span>
              </div>
              <div style="padding-right: 25px">
                <span>审核医生：</span><span>{{ PrescriptionDetail.doctorName }}{{"【" + PrescriptionDetail.doctorPhone + "】"}}</span>
              </div>
              <!-- <div style="padding-right: 25px">
                <span>药剂师：</span><span>{{ PrescriptionDetail.pharmacistName }}{{"【" + PrescriptionDetail.pharmacistPhone+  "】"}}</span>
              </div> -->
            </div>
            <div style="width: 20%; text-align: right">
              <div class="tagEdit" v-if="userInfo.IsAuditDiagnose">编辑</div>
            </div>
            <div class="imgBox" v-if="patientDetail.mzPrescriptionOut.auditStatus == 0">
              <img :src="imgurl1" alt="" srcset="" />
            </div>
            <div class="imgBox" v-if="patientDetail.mzPrescriptionOut.auditStatus == 1">
              <img :src="imgurl0" alt="" srcset="" />
            </div>
            <div class="imgBox" v-if="patientDetail.mzPrescriptionOut.auditStatus == -1">
              <img :src="imgurl2" alt="" srcset="" />
            </div>
          </div>
          <!-- <div class="numDiv2 flex-l" v-if="patientDetail.mzPrescriptionOut.refusalCause">
            <div class="flex-start" style="color:#fc1414">
              <div>拒绝理由：{{ patientDetail.mzPrescriptionOut.refusalCause ? patientDetail.mzPrescriptionOut.refusalCause : "" }}</div>
            </div>
          </div> -->
          <el-button-group style="margin-top:30px">
              <el-button @click="changeDetails(1)"  :type="changeTable == 1 ? 'primary' : ''">问诊详情</el-button>
              <el-button @click="changeDetails(2)" :type="changeTable == 2 ? 'primary' : ''">处方详情</el-button>
              <el-button @click="changeDetails(3)" :type="changeTable == 3 ? 'primary' : ''">时间轴</el-button>
          </el-button-group>
        </div>
        <div class="titleTopCenterCenterTop" v-if="changeTable == 1">
          <div class="">
            <div class="titleTopLeftTable" style="border: none">
              <div class='flex-l' style="margin-top: 5px">
                <div style="font-weight: bold;">患者：</div>
                <div>
                  <span style="padding-right: 10px">{{ patientDetail.mzPrescriptionOut.patientName }}</span>,
                  <span style="padding-right: 10px">{{ patientDetail.mzPrescriptionOut.age }}</span>,
                  <span style="padding-right: 10px">{{ patientDetail.mzPrescriptionOut.sex ? "男" : "女" }}</span>,
                  <span style="padding-right: 10px">{{ patientDetail.mzPrescriptionOut.patientPhone }}</span>
                  <span style="padding-right: 10px">{{ patientDetailInfo.diseaseName }}</span>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 30px">
                <div style="font-weight: bold;">主诉：</div>
                <div style="">
                  <div>{{ patientDetailAll.main ? patientDetailAll.main : "-" }}</div>
                </div>
              </div>

              <div class="flex-l" style="margin-top: 30px">
                <div style="font-weight: bold;">鉴别分析</div>
              </div>
              <div style="margin-top: 5px">
                <div class="discriminatoryAnalysisBox" v-if="patientDetailAll.discriminatoryAnalysis">
                  <span>{{ patientDetailAll.discriminatoryAnalysis }}</span>
                </div>
              </div>
              <div class="flex-l" style=" margin-top: 20px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">家族病史</div>
                <div style="margin-left: 15px">
                  <span>{{ patientDetailAll.family ? patientDetailAll.family : "-" }}</span>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 20px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">过敏史</div>
                <div style="margin-left: 15px">
                  <span>{{ patientDetailAll.irritability ? patientDetailAll.irritability : "-" }}</span>
                </div>
              </div>
              <div class="flex-l" style=" margin-top: 20px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">既往史</div>
                <div style="margin-left: 15px">
                  <span>{{ patientDetailAll.past ? patientDetailAll.past : "-" }}</span>
                </div>
              </div>
              <div style="margin-top: 20px">
                <div class="flex-l">
                  <div style="margin-right: 15px">
                    <span>体温：</span><span>{{ patientDetailAll.temperature ? patientDetailAll.temperature : "-" }} ℃</span>
                  </div>
                  <div style="margin-right: 15px">
                    <span>体重：</span><span>{{ patientDetailAll.weight ? patientDetailAll.weight : "-" }}kg</span>
                  </div>
                  <div style="margin-right: 15px">
                    <span>血压：</span><span>{{ patientDetailAll.heightTension ? patientDetailAll.heightTension : "-" }}/{{ patientDetailAll.lowTension ? patientDetailAll.lowTension : "-" }}mmHg</span>
                  </div>
                  <div style="margin-right: 15px">
                    <span>身高：</span><span>{{ patientDetailAll.height ? patientDetailAll.height : "-" }}CM</span>
                  </div>
                  <div style="margin-right: 15px">
                    <span>呼吸：</span><span>{{ patientDetailAll.breathe ? patientDetailAll.breathe : "-" }}次/分</span>
                  </div>
                  <div style="margin-right: 15px">
                    <span>脉搏：</span><span>{{ patientDetailAll.pulse ? patientDetailAll.pulse : "-" }}次/分</span>
                  </div>
                  <div style="margin-right: 15px">
                    <span>其他：</span><span>{{ patientDetailAll.otherCheck ? patientDetailAll.otherCheck : "-" }}</span>
                  </div>
                </div>

              </div>
              <div class="flex-l" style="margin-top: 20px">
                <div style="font-weight: bold;">症状图片</div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px; flex-wrap: wrap" v-if="patientDetailAll.patientImageAttas" class="flex-l">
                <div v-for="(item, index) in patientDetailAll.diagnoseImgList" :key="index">
                  <!-- <img :src="Services.Authorization + item.attaFile.filePath" class="avatar" /> -->
                  <a :href="Services.Authorization + item.filePath"  target="_blank" title="查看最大化图片">
                  <img :src="Services.Authorization + item.filePath" class="avatar" />
                </a>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 20px">
                <div style="font-weight: bold;">处方图片</div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px; flex-wrap: wrap" v-if="patientDetailAll.prescriptionImgList" class="flex-l">
                <div v-for="(item, index) in patientDetailAll.prescriptionImgList" :key="index">
                  
                <a :href="Services.Authorization + item.filePath"  target="_blank" title="查看最大化图片">
                  <img :src="Services.Authorization + item.filePath" class="avatar" />
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="titleTopCenterCenterTop" v-if="changeTable == 2">
            <div class="flex-l-s titleSize14" style="margin-bottom: 20px">
              <div> </div>
              <!-- type != 'ziji'我的审核  type == 'ziji'我的提交-->
              <div class="flex-l" style="margin: 20px" v-if="(patientDetail.mzPrescriptionOut.auditStatus == 0 && type != 'ziji') || patientDetail.mzPrescriptionOut.auditStatus == -1 || patientDetail.mzPrescriptionOut.auditStatus == 6">
                <el-button type="primary" v-if="patientDetail.mzPrescriptionOut.waitAuditUserId != 0 || patientDetail.mzPrescriptionOut.waitAuditUserId != 0 && type != 'ziji'" @click="goSuccessEdit('patientFormPre')">处方修改</el-button>
                <el-button v-if="patientDetail.mzPrescriptionOut.waitAuditUserId != 0 || patientDetail.mzPrescriptionOut.waitAuditUserId != 0 && type != 'ziji'" type="success" :loading="nosucecessloading"  @click="goSuccess('patientFormPre')">{{type == 'ziji' ? '重新提交' : '审核通过'}} </el-button>
                <el-button type="warning" v-if="type != 'ziji'" @click="gofail('patientFormPre')">审核拒绝</el-button>
                <el-button v-if="patientDetail.mzPrescriptionOut.waitAuditUserId != 0 || patientDetail.mzPrescriptionOut.waitAuditUserId != 0 && type != 'ziji'" type="danger" @click="goComtabClick1">用药审查</el-button>
              </div>
            </div>
          <!-- <div v-if="patientDetail.mzPrescriptionOut.auditStatus == 0 && type != 'ziji'" style="color: #00afb5; margin-left: 10px;cursor: pointer;" @click="goAddClick">审核修改</div> -->
          <el-form :model="patientFormPre" ref="patientFormPre" label-position="top">
            <div class="table3Box" v-for="(itemPre, indexPre) in patientFormPre.patientFormPreList" :key="indexPre">
              <el-form-item :prop="'patientFormPreList.' + indexPre + '.prescriptionName'" label="中药方剂名称" :rules="[{ required: true, message: '请输入中药方剂名称', trigger: 'blur' }]">
                <div class="flex-l titleSize14" style="margin-bottom: 20px">
                  <el-input v-model="itemPre.prescriptionName" placeholder="请输入中药方剂名称" style="width: 40%" :disabled="isAllPoint"></el-input>
                  <!-- <div class="flex-l" style="margin: 0px 20px" v-if="(patientDetail.mzPrescriptionOut.auditStatus == 0 && type != 'ziji') || patientDetail.mzPrescriptionOut.auditStatus == -2 || patientDetail.mzPrescriptionOut.auditStatus == -1">
                    <el-button type="primary" @click="goSuccessEdit('patientFormPre')">处方修改</el-button>
                    <el-button type="success" :loading="nosucecessloading"  @click="goSuccess('patientFormPre')">{{type == 'ziji' ? '重新提交' : '审核通过'}} </el-button>
                    <el-button type="warning" v-if="type != 'ziji'" @click="gofail('patientFormPre')">审核拒绝</el-button>
                    <el-button type="danger" @click="goComtabClick1">用药审查</el-button>
                  </div> -->
                </div>
              </el-form-item>
              <el-table :data="itemPre.mzPrescriptionDetail" border>
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column prop="drugOrgId" align="center" label="成分">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'patientFormPreList.' + indexPre + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'"
                      :rules="[
                        { required: true, message: '请选择成分', trigger: 'blur' },
                        { pattern: /^(?!0$).*/, message: '请选择成分', trigger: 'blur' },
                      ]">
                      <div style="position: relative">
                        <el-select :disabled="isAllPoint" v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                          <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                        </el-select>
                        <div class="infoWarn" v-if="scope.row.drugOrgId == '0'" style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="weight" align="center" label="用量">
                  <template slot-scope="scope">
                    <el-form-item :prop="'patientFormPreList.' + indexPre + '.mzPrescriptionDetail.' + scope.$index + '.weight'" :rules="[{ required: true, message: '请选择用量', trigger: 'blur' }]">
                      <div style="position: relative">
                        <el-input :disabled="isAllPoint" v-model="scope.row.weight" size="small" placeholder="用量(g)"
                          onkeyup="this.value=this.value.replace(/[^\d.]/g, '').replace(/^\D*(\d{1,12}(?:\.\d{0,3})?).*$/g, '$1')"
                          onafterpaste="this.value=this.value.replace(/[^\d.]/g, '').replace(/^\D*(\d{1,12}(?:\.\d{0,3})?).*$/g, '$1')"
                        >
                          <!-- <el-select style=" width: 65px;" v-if="scope.row.dosageList.dosageUnit" slot="append" v-model="scope.row.dosageList.dosageUnit" size='mini' placeholder="单位">
                                              <el-option v-for="item in kgOption" :key="item.value"
                                                  :label="item.value" :value="item.value"> </el-option>
                                          </el-select> -->
                        </el-input>
                        <el-tooltip effect="dark" content="库存不足" placement="bottom" v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber)|| scope.row.weight > scope.row.drugInventoryNumber">
                          <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1" type="text"></el-button>
                        </el-tooltip>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="recommendDosage" align="center" label="推荐用量" width="80">
                    <template slot-scope="scope">
                        <div style="position: relative">
                          {{ scope.row.recommendDosage ? scope.row.recommendDosage : '--'}}
                          <el-tooltip effect="dark" content="与推荐用量不符" placement="bottom"
                              v-if="scope.row.weight < scope.row.recommendDosageMin || scope.row.weight > scope.row.recommendDosageMax">
                              <el-button style="color: #e6a23c; font-size: 16px" icon="el-icon-s-management" class="infoWarnCom"
                                type="text"></el-button>
                            </el-tooltip>
                        </div>
                    </template>

                  </el-table-column>
                  <el-table-column prop="remark" align="center" label="炮制方法" width="80">
                    <template slot-scope="scope">
                      <div style="position: relative">
                          {{ scope.row.remark ? scope.row.remark : '--'}}
                        </div>
                    </template>
                  </el-table-column>
                <el-table-column prop="decoct" align="center" label="煎药方法">
                  <template slot-scope="scope">
                    <el-form-item>
                      <el-select :disabled="isAllPoint" v-model="scope.row.decoct" size="mini" placeholder="煎药方法">
                        <el-option v-for="item in usageOption" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="130" align="center">
                  <template slot-scope="scope">
                    <div class="flex-l">
                      <div>￥{{ scope.row.tradingPrice ? parseFloat(itemPre.dosage * (scope.row.weight ? parseFloat(scope.row.weight) : 0) * 1 * scope.row.tradingPrice ).toFixed(2) : "0.00" }}</div>
                      <i v-if="!isAllPoint" class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="gondeletePre(indexPre, scope.$index)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div v-if="!isAllPoint" class="titleSize14 titleTopright1 titleTopright1Line" @click="gonaddPre(indexPre)">添加中药材</div>
              <el-form-item :prop="'patientFormPreList.' + indexPre + '.dosage'" label="剂数" :rules="[{ required: true, message: '请输入剂数', trigger: 'blur' }]">
                <div class="flex-l" style="margin-top: 15px"><el-input-number :disabled="isAllPoint" class="stepInput" v-model="itemPre.dosage" controls-position="right" :min="1"></el-input-number>剂</div>
              </el-form-item>
              <el-form-item :prop="'patientFormPreList.' + indexPre + '.dosageNumber'" label="每日剂量" :rules="[{ required: true, message: '请输入每日剂量', trigger: 'blur' }]">
                <div class="flex-l" style="margin-top: 15px"><el-input-number :disabled="isAllPoint" class="stepInput" v-model="itemPre.dosageNumber" controls-position="right" :min="1"></el-input-number>剂</div>
              </el-form-item>
              <el-form-item :prop="'patientFormPreList.' + indexPre + '.frequency'" label="用药频率" :rules="[{ required: true, message: '请输入用药频率', trigger: 'blur' }]">
                <div class="flex-l" style="margin-top: 15px">
                  <el-select :disabled="isAllPoint" v-model="itemPre.frequency" placeholder="用药频率">
                    <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item :prop="'patientFormPreList.' + indexPre + '.usage'" label="用法：" :rules="[{ required: true, message: '请输入用法：', trigger: 'blur' }]">
                <div class="flex-l" style="margin-top: 15px">
                  <el-select :disabled="isAllPoint" v-model="itemPre.usage" placeholder="用法">
                    <el-option v-for="item in usage" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item :prop="'patientFormPreList.' + indexPre + '.request'" label="服用要求：" :rules="[{ required: true, message: '请输入服用要求：', trigger: 'blur' }]">
                <div class="flex-l" style="margin-top: 15px">
                  <el-select :disabled="isAllPoint" v-model="itemPre.request" placeholder="服用要求">
                    <el-option v-for="item in requestOption" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
            <div class="table3Box" v-for="(itemPreCY, indexPreCY) in patientFormPre.patientFormPreCY" :key="indexPreCY">
              <el-form-item :prop="'patientFormPreCY.' + indexPreCY + '.prescriptionName'" label="成药处方名称" :rules="[{ required: true, message: '请输入成药处方名称', trigger: 'blur' }]">
                <div class="flex-l titleSize14" style="margin-bottom: 20px">
                  <div class=""></div>
                  <el-input :disabled="isAllPoint" v-model="itemPreCY.prescriptionName" placeholder="请输入成药处方名称" style="width: 40%"></el-input>
                  <!-- <div class="flex-l" style="margin: 0px 20px" v-if="(patientDetail.mzPrescriptionOut.auditStatus == 0 && type != 'ziji') || patientDetail.mzPrescriptionOut.auditStatus == -2 || patientDetail.mzPrescriptionOut.auditStatus == -1">
                    <el-button type="primary" @click="goSuccessEdit('patientFormPre')">处方修改</el-button>
                    <el-button type="success" :loading="nosucecessloading"  @click="goSuccess('patientFormPre')">{{type == 'ziji' ? '重新提交' : '审核通过'}} </el-button>
                    <el-button type="warning" v-if="type != 'ziji'" @click="gofail('patientFormPre')">审核拒绝</el-button>
                    <el-button type="danger" @click="goSuccessCom('patientFormPre')">用药审查</el-button>
                  </div> -->
                </div>
              </el-form-item>

              <el-table :data="itemPreCY.mzPrescriptionDetail" border>
                <el-table-column prop="drugOrgId" align="center" label="药品名称">
                  <template slot-scope="scope">
                    <el-form-item :prop="'patientFormPreCY.' + indexPreCY + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'" :rules="[{ required: true, message: '请选择成分', trigger: 'blur' }]">
                      <div style="position: relative">
                        <el-select :disabled="isAllPoint" v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                          <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                        </el-select>
                        <div class="infoWarn" v-if="scope.row.drugOrgId == '0'" style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="groupNumber" align="center" label="组号" width="120">
                  <template slot-scope="scope">
                    <el-form-item :prop="'patientFormPreCY.' + indexPreCY + '.mzPrescriptionDetail.' + scope.$index + '.groupNumber'">
                      <el-input :disabled="isAllPoint" v-model="scope.row.groupNumber" size="small" placeholder="输入组号"> </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="usage" align="center" label="用法" width="130">
                  <template slot-scope="scope">
                    <el-form-item :prop="'patientFormPreCY.' + indexPreCY + '.mzPrescriptionDetail.' + scope.$index + '.usage'" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                      <el-select :disabled="isAllPoint" v-model="scope.row.usage" size="mini" placeholder="用法">
                        <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="frequency" align="center" label="用药频率" width="150">
                  <template slot-scope="scope">
                    <el-form-item :prop="'patientFormPreCY.' + indexPreCY + '.mzPrescriptionDetail.' + scope.$index + '.frequency'" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                      <el-select :disabled="isAllPoint" v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                        <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                  <template slot-scope="scope">
                    <el-form-item :prop="'patientFormPreCY.' + indexPreCY + '.mzPrescriptionDetail.' + scope.$index + '.dosageList.dosage'" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                      <el-input :disabled="isAllPoint" v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                        <el-select :disabled="isAllPoint" style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit" size="mini" placeholder="单位">
                          <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="defaultDosage" align="center" label="开药量" width="160">
                  <template slot-scope="scope">
                    <el-form-item :prop="'patientFormPreCY.' + indexPreCY + '.mzPrescriptionDetail.' + scope.$index + '.defaultDosageList.defaultDosage'" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                      <div style="position: relative">
                        <el-input :disabled="isAllPoint" v-model="scope.row.defaultDosageList.defaultDosage" size="small" placeholder="开药量">
                          <el-select :disabled="isAllPoint" style="width: 75px" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size="mini" placeholder="单位">
                            <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                          </el-select>
                        </el-input>
                        <el-tooltip effect="dark" content="库存不足" placement="bottom" v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.defaultDosageList.defaultDosage > scope.row.drugInventoryNumber">
                          <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1" type="text"></el-button>
                        </el-tooltip>
                        <!-- {{scope.row.defaultDosageList.defaultDosage }} {{scope.row.drugInventoryNumber}} -->
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="100" align="center">
                  <template slot-scope="scope">
                    <div class="flex-l">
                      <div>￥{{   scope.row.tradingPrice ? parseFloat(scope.row.defaultDosageList.defaultDosage * 1 * scope.row.tradingPrice).toFixed(2)   : "0.00" }}</div>
                      <i v-if="!isAllPoint" class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="gondeletePreCY(indexPreCY, scope.$index)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div v-if="!isAllPoint" class="titleSize14 titleTopright1 titleTopright1Line" @click="gonaddPreCY(indexPreCY)">添加药品</div>
            </div>
            <div class="table3Box" v-for="(itemPreSY, indexPreSY) in patientFormPre.patientFormPreSY" :key="indexPreSY">
              <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.prescriptionName'" label="输液处方名称" :rules="[{ required: true, message: '请输入输液处方名称', trigger: 'blur' }]">
                <div class="flex-l titleSize14" style="margin-bottom: 20px">
                  <div class=""></div>
                  <el-input :disabled="isAllPoint" v-model="itemPreSY.prescriptionName" placeholder="输液处方：" style="width: 40%"></el-input>
                  <!-- <div class="flex-l" style="margin: 0px 20px" v-if="(patientDetail.mzPrescriptionOut.auditStatus == 0 && type != 'ziji') || patientDetail.mzPrescriptionOut.auditStatus == -2 || patientDetail.mzPrescriptionOut.auditStatus == -1">
                    <el-button type="primary" @click="goSuccessEdit('patientFormPre')">处方修改</el-button>
                    <el-button type="success" :loading="nosucecessloading"  @click="goSuccess('patientFormPre')">{{type == 'ziji' ? '重新提交' : '审核通过'}} </el-button>
                    <el-button type="warning" v-if="type != 'ziji'" @click="gofail('patientFormPre')">审核拒绝</el-button>
                    <el-button type="danger" @click="goSuccessCom('patientFormPre')">用药审查</el-button>
                  </div> -->
                </div>
              </el-form-item>
              <div v-for="(itemComSY1, indexComSY1) in itemPreSY.zucompositionList" :key="indexComSY1" style="margin-top: 20px">
                <div class="flex-l titleSize14">
                  <div class="">组{{ indexComSY1 * 1 + 1 }}</div>
                  <div class="flex-l titleTopright1 titleSize14">
                    <div v-if="!isAllPoint" style="margin-left: 15px" @click="godeleteSYzuList(indexPreSY, indexComSY1)">删除组</div>
                  </div>
                </div>
                <el-table :data="itemComSY1.mzPrescriptionTitle" border>
                  <el-table-column prop="usage" align="center" label="用法">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.usage'" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                        <el-select :disabled="isAllPoint" v-model="scope.row.usage" size="mini" placeholder="用法">
                          <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="frequency" align="center" label="用药频率">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.frequency'">
                        <el-select :disabled="isAllPoint" v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                          <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="drippingSpeedList" align="center" label="滴速">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.drippingSpeedList.drippingSpeed'">
                        <el-input :disabled="isAllPoint" v-model="scope.row.drippingSpeedList.drippingSpeed" size="small" placeholder="单次剂量">
                          <el-select :disabled="isAllPoint" style="width: 75px" slot="append" v-model="scope.row.drippingSpeedList.drippingSpeedUnit" size="mini" placeholder="单位">
                            <el-option v-for="item in SpeeOption" :key="item" :label="item" :value="item"> </el-option>
                          </el-select>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="startTime" align="center" label="起始日期">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.startTime'">
                        <el-date-picker v-model="scope.row.startTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd "> </el-date-picker>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="days" align="center" label="连续天数">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.days'">
                        <el-input :disabled="isAllPoint" v-model="scope.row.days" size="small" placeholder="天数"> </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="itemComSY1.mzPrescriptionDetail" border style="margin-top: 10px">
                  <el-table-column prop="drugOrgId" align="center" label="药品名称">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'" :rules="[{ required: true, message: '请输入成分', trigger: 'blur' }]">
                        <div style="position: relative">
                          <el-select :disabled="isAllPoint" v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                            <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                          </el-select>
                          <div class="infoWarn" v-if="scope.row.drugOrgId == '0'" style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                        </div>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="st" align="center" label="皮试">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.st'">
                        <el-input :disabled="isAllPoint" v-model="scope.row.st" size="small" placeholder="皮试"> </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                    <template slot-scope="scope">
                      <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.dosageList.dosage'" :rules="[{ required: true, message: '请输入单次剂量', trigger: 'blur' }]">
                        <el-input :disabled="isAllPoint" v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                          <el-select :disabled="isAllPoint" style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit" size="mini" placeholder="单位">
                            <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                          </el-select>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="defaultDosageList" align="center" label="开药量" width="160">
                    <template slot-scope="scope">
                      <div style="position: relative"> 
                        <el-form-item :prop="'patientFormPreSY.' + indexPreSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.defaultDosageList.defaultDosage'" :rules="[{ required: true, message: '请输入开药量', trigger: 'blur' }]">
                        <el-input :disabled="isAllPoint" v-model="scope.row.defaultDosageList.defaultDosage" size="small" placeholder="开药量">
                          <el-select :disabled="isAllPoint" style="width: 75px" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size="mini" placeholder="单位">
                            <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                          </el-select>
                        </el-input>
                            <el-tooltip effect="dark" content="库存不足" placement="bottom" v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.defaultDosageList.defaultDosage > scope.row.drugInventoryNumber">
                          <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1" type="text"></el-button>
                        </el-tooltip>
                      </el-form-item>
                      </div> 
                    </template>
                  </el-table-column>
                  <el-table-column width="100" align="center">
                    <template slot-scope="scope">
                      <div class="flex-l">
                        <div>￥{{   scope.row.tradingPrice ? parseFloat(scope.row.defaultDosageList.defaultDosage * 1 * scope.row.tradingPrice).toFixed(2)   : "0.00" }}</div>
                        <i v-if="!isAllPoint" class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="godeletSYzuDrugList(indexPreSY, indexComSY1, scope.$index)"></i>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="flex-l titleSize14">
                  <div v-if="!isAllPoint" class="titleSize14 titleTopright1" @click="goaddSYzuDrugList(indexPreSY, indexComSY1)">添加药品</div>
                  <div v-if="!isAllPoint" class="titleSize14 titleTopright1" @click="goaddSYzuList(indexPreSY)" style="margin-left: 15px">添加组</div>
                </div>
              </div>
            </div>
            <div v-if="patientFormPre.mzOtherCharges.length != 0" style="margin-left:15px;margin-right: 10%;">
                  <div class="flex-l" style="margin-top: 10px">
                    <div class="titleTopLeftTableTag"></div>
                    <div style="color: #999999">附加费用</div>
                  </div>
                  <div v-for="(itemCharge, indexCharge) in patientFormPre.mzOtherCharges" :key="indexCharge" style="margin-top: 10px">
                      <div class="flex-l">
                        <el-input :disabled="true" v-model="itemCharge.chargeName" class="stepInput"></el-input>
                        <!-- <div>￥</div> -->
                        <!-- <el-input :disabled="isAllPoint" v-model="itemCharge.money" style="width: 7%; margin: 0 10px"></el-input> -->
                        <!-- <div><i v-if="!isAllPoint" class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="deleteCharges(indexCharge)"></i></div> -->
                      </div>
                  </div>
                  <el-button v-if="!isAllPoint" size="mini" type="primary" icon="el-icon-plus" @click="goAddCharges" style="margin-top:10px;"> </el-button>
            </div>
            <!-- <div class="Content3TitleBox">
              <div class="Content3TitleBoxRight">
                <span>合计：</span><span class="Content3TitleBoxRightSpan">￥{{ parseFloat(allTotalMoney).toFixed(2) }}</span>
              </div>
            </div> -->

          </el-form>

        </div>
        <div class="titleTopCenterCenterTop" v-if="changeTable == 3">
            <el-timeline style="margin-top:40px" v-for="(item,index) in timelineList" :key="index">
              <el-timeline-item :timestamp="item.createTime | timefilters" placement="top">
                <el-card>
                  <h3 v-if="item.type == 1">提交处方审核申请</h3>
                  <h3 style="color:#E9B249" v-if="item.type == 2 && item.state == 0">审核医生拒绝</h3>
                  <h3 style="color:#00AFB5" v-if="item.type == 2 && item.state == 1">审核医生通过</h3>
                  <div style="color:#FF0000" v-if="item.type == 2 && item.state == 0">拒绝原因:{{item.remark}}</div>
                  <!-- <h3 style="color:#00AFB5" v-if="item.type == 3 && item.state == 1">药剂师通过</h3>
                  <h3 style="color:#E9B249" v-if="item.type == 3 && item.state == 0">药剂师拒绝</h3>
                  <div style="color:#FF0000" v-if="item.type == 3 && item.state == 0">拒绝原因:{{item.remark}}</div> -->
                  <h3 v-if="item.type == 4">已支付</h3>
                  <h3 v-if="item.type == 5">备药完成</h3>
                  <h3 v-if="item.type == 6">订单已发货</h3>
                  <h3 v-if="item.type == 7">订单已收货</h3>
                  <!-- <div>药剂师: {{item.auditPharmacistName}}{{"【" + item.auditPharmacistPhone + "】"}}</div> -->
                  <div>审核医生: {{item.auditUserName ? item.auditUserName : item.userName}}{{"【" + (item.auditUserPhone ? item.auditUserPhone : item.userPhone) + "】"}}</div>
                  <div>开方医生: {{item.userName}}{{"【" + item.userPhone + "】"}}</div>
                  <div v-if="item.type == 5">备药医生: {{item.operatorUserName}}{{"【" + item.operatorUserPhone + "】"}}</div>
                  <div v-if="item.type == 6">备药医生: {{item.operatorUserName}}{{"【" + item.operatorUserPhone + "】"}}</div>
                  <div v-if="item.type == 7">备药医生: {{item.operatorUserName}}{{"【" + item.operatorUserPhone + "】"}}</div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
        </div>

      </div>
    </div>
    <el-drawer title="审核处方" :visible.sync="isdrawer" direction="rtl" size="1200px">

    </el-drawer>
    <el-dialog title="电子签名" :visible.sync="esigndialogVisible" width="30%" :close-on-click-modal ="false">
      <vue-esign style="border: 1px solid #ddd; height: 200px" ref="esign" :isCrop="isCrop" :width="600" :height="200" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor"></vue-esign>
      <div class="flex-l-b" style="margin-top:20px">
        <div class="flex-l" v-if="UserSignImg">
          <el-checkbox v-model="signMent">使用上次签名 </el-checkbox>
          <img style="width:100px;height:40px" :src="Services.Authorization + UserSignImg" alt="" srcset="" />
        </div>
        <div v-else> </div>
        <span>
          <el-button @click="handleReset">清空签名</el-button>
          <el-button @click="esigndialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleGenerate">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog title="拒绝理由" :visible.sync="cancledialogVisible" width="30%">
      <div>
          <el-input type="textarea" v-model="cancledValue"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancledialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureCancleGenerate">确 定</el-button>
      </span>
    </el-dialog>
        <!-- 用药审查配伍 -->
    <el-dialog title="提示" :visible.sync="comDialogVisible" width="60%">
      <div class="pei-bottom">
        <!-- 推荐用量提示 -->
        <div v-if="!compatibilityResultShow && recommendPreList.length > 0">
           <span style="color:#00AFB5;font-size:16px" v-for="(item,index) in recommendPreList" :key="index">{{item.drugOrgName + ','}}</span>  <span style="color:#FF0000;font-size:16px">与推荐用量不符，请谨慎使用</span>
        </div>
        <ul class="tab-tilte clearfix" v-if="compatibilityResultShow">
          <li @click="cur = 0" :class="[{ active: cur == 0 }, total > 0 ? '' : 'greenbg']">
            <i :class="total > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>全部</em><span>{{ total }}</span>
          </li>
          <li @click="cur = 1" :class="[{ active: cur == 1 }, similarTotal > 0 ? '' : 'greenbg']">
            <i :class="similarTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>重复用药</em><span>{{ similarTotal
              }}</span>
          </li>
          <li @click="cur = 2" :class="[{ active: cur == 2 }, compatibilityTotal > 0 ? '' : 'greenbg']">
            <i :class="compatibilityTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>相互作用</em><span>{{
              compatibilityTotal }}</span>
          </li>
          <li @click="cur = 3" :class="[{ active: cur == 3 }, crowdTotal > 0 ? '' : 'greenbg']">
            <i :class="crowdTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>特殊人群</em><span>{{ crowdTotal
              }}</span>
          </li>
          <li @click="cur = 4" :class="[{ active: cur == 4 }, dietTotal > 0 ? '' : 'greenbg']">
            <i :class="dietTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>饮食禁忌</em><span>{{ dietTotal
              }}</span>
          </li>
          <li @click="cur = 5" :class="[{ active: cur == 5 }, avoidTotal > 0 ? '' : 'greenbg']">
            <i :class="avoidTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>用药禁忌</em><span>{{ avoidTotal
              }}</span>
          </li>
        </ul>
        <div class="tab-content">
          <ul v-show="cur == 0">
            <li v-for="(similar, index) in similarList" :key="index + '1'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <h3>
                  【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                </h3>
                <div class="show-more">
                  <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="peitab-mes">
                <p v-html="similarhtmlRaw(similar)"></p>
              </div>
            </li>
            <li v-for="(compare, index) in compatibilityList" :key="index + '2'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <div v-if="compare.compatibilityType == 7">
                  <h3 v-for="(tip, index1) in compare.tips" :key="index1">
                    【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                  </h3>
                </div>
                <div v-else>
                  <h3>
                    【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
              <div v-if="compare.tips" class="peitab-mes">
                <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + '3'">
                  <div v-if="types.keys <= 3">
                    <p v-for="(val, index2) in types.value" :key="index2 + '4'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 4">
                    <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + '6'">
                      <p v-html="namehtmlraw(val)"></p>
                    </div>
                  </div>
                  <div v-if="types.keys == 5">
                    <p v-for="(val, index2) in types.value" :key="index2 + '7'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 7">
                    <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                  </div>
                  <div v-if="types.keys == 8">
                    <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + '8'"
                      v-html="namehtmlraw(val)"></p>
                  </div>
                  <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail"
                    @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">
                    查看详情</p>
                </div>
              </div>
              <div v-else class="peitab-mes">
                <div>
                  <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
                </div>
              </div>
            </li>
            <li v-for="(ins, index) in getcrowdList()" :key="index + '9'">
              <div v-for="(item3, index1) in ins.value" :key="index1 + 'a'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【特殊人群1】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(val, index2) in item3.items" :key="index2 + 'b'" v-html="contenthtmlRaw(val)"></p>
                </div>
              </div>
            </li>
            <template v-if="getNumByType(instructions, 2) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 'e'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【饮食禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'f'">
                    <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                  </div>
                </div>
              </li>
            </template>
            <template v-if="getNumByType(instructions, 1) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'c'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【用药禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(item, index1) in ins.items" :key="index1 + 'd'" v-html="contenthtmlRaw(item)"></p>
                </div>
              </li>
            </template>
          </ul>
          <div v-show="cur == 1">
            <li v-for="(similar, index) in similarList" :key="index + 'h'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <h3>
                  【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                </h3>
                <div class="show-more">
                  <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="peitab-mes">
                <p v-html="similarhtmlRaw(similar)"></p>
              </div>
            </li>
          </div>
          <div v-show="cur == 2">
            <li v-for="(compare, index) in compatibilityList" :key="index + 'i'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <div v-if="compare.compatibilityType == 7">
                  <h3 v-for="(tip, index1) in compare.tips" :key="index1 + 'j'">
                    【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                  </h3>
                </div>
                <div v-else>
                  <h3>
                    【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
              <div v-if="compare.tips" class="peitab-mes">
                <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + 'k'">
                  <div v-if="types.keys <= 3">
                    <p v-for="(val, index2) in types.value" :key="index2 + 'l'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 4">
                    <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + 'n'">
                      <p v-html="namehtmlraw(val)"></p>
                    </div>
                  </div>
                  <div v-if="types.keys == 5">
                    <p v-for="(val, index2) in types.value" :key="index2 + 'o'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 7">
                    <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                  </div>
                  <div v-if="types.keys == 8">
                    <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + 'p'"
                      v-html="namehtmlraw(val)"></p>
                  </div>
                  <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail"
                    @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">
                    查看详情</p>
                </div>
              </div>
              <div v-else class="peitab-mes">
                <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
              </div>
            </li>
          </div>
          <div v-show="cur == 3">
            <li v-for="(ins, index) in getcrowdList()" :key="index + 'q'">
              <div v-for="(item3, index1) in ins.value" :key="index1 + 'r'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【特殊人群】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(val, index2) in item3.items" :key="index2 + 's'" v-html="contenthtmlRaw(val)"></p>
                </div>
              </div>
            </li>
          </div>
          <div v-show="cur == 4">
            <template v-if="getNumByType(instructions, 2) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 't'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【饮食禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'u'">
                    <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                  </div>
                  <div v-for="(item, index1) in dietGroup(ins.items.filter((i) => i.target != null))"
                    :key="index1 + 'v'">
                    <p v-html="contenthtmlRaw(item, true)"></p>
                  </div>
                </div>
              </li>
            </template>
          </div>
          <div v-show="cur == 5">
            <template v-if="getNumByType(instructions, 1) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'w'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【用药禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(item, index1) in ins.items" :key="index1 + 'x'" v-html="contenthtmlRaw(item)"></p>
                </div>
              </li>
            </template>
          </div>
        </div>
      </div>
      <div class="flex-l-b">
        <el-checkbox  v-if="!compatibilityResultShow" v-model="Agreement">我已知悉以上药品所包含的风险并愿意承担相应责任</el-checkbox>
        <div v-else></div>
        <span>
          <el-button @click="cancleComDialogVisible">取 消</el-button>
          <el-button type="primary" @click="goComDialogVisible">确 定</el-button>
        </span>
      </div>
      <!-- <span slot="footer" class="dialog-footer">

      </span> -->
    </el-dialog>
    <div class="titleTopLine"></div>
  </div>
  
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import { getckOptionsNames, addCompare, nameConvertValue, getCompatibilityList, removeCompare, updateCompare, removeCompareAll, namehtmlraw, tipGroup, dietGroup, contenthtmlRaw, getcrowdList } from "../../utils/Compatibility";
import { Compatibility } from "../../components/Compatibility/Compatibility";
import { tocompareDetail } from "../../utils/relation";
import printJS from "print-js";
import html2canvas from "html2canvas";
import storage from "@/utils/customstorage";
import servicesData from '../../../public/services.json'
export default {
  components: {
    intNavheader,
    waitNumber
  },
  data() {
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var compatibility = new Compatibility(this.TokenClient, this.Services.Drug);
    return {
      patientDomain: patient,
      drugpatientDomain: drugpatient,
      compatibilityDomain: compatibility,
      userInfo: this.$store.state.localData.userInfo,
      lineWidth: 6, //画笔粗细
      lineColor: "#000000", //画笔颜色
      bgColor: "", //画布背景色，为空时画布背景透明
      imgurl: "", //签名生成的图片
      isCrop: false, //是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
      patientDetail: {},
      patientDetailAll: {},
      patientDetailInfo: {},
      addtoNew: false,
      inputValue: "",
      inputValue2: "",
      num: "",
      UserSignImg:'',
      signMent:false,
      activeName: "first",
      isdrawer: false,
        // 是否选择免审核
      AuditNextDataSuc:false,
      compatibilityResultShow:true,
      Agreement:false,
      recommendPreList:[],
      // 配伍数据
      comDialogVisible:false,
      cur: 0,
      similarTotal: 0, //同类药条数
      compatibilityTotal: 0, //配伍条数
      crowdTotal: 0, //老人、儿童、妊娠、哺乳 总条数
      dietTotal: 0, //饮食条数
      avoidTotal: 0, //禁忌条数
      total: 0, //总条数
      compatibilityList: [], //配伍
      similarList: [], //同类药
      instructions: [], // 用药须知    禁忌1   饮食2   老人3    儿童4    妊娠5   哺乳6
      types: [],
      crowdList: [], // 特殊人群
      fruit: getCompatibilityList(),
      patientFormPre: {
        patientFormPreList: [],
        patientFormPreCY: [],
        patientFormPreSY: [],
        mzOtherCharges:[]
      },
      remarkOption: ["口服", "外用", "滴眼", "静脉注射", "静脉滴注", "肌内注射", "皮下注射", "皮内注射", "腔内注射", "椎管注射", "动脉注射", "心内注射", "球内注射", "皮下埋植", "口腔喷雾", "口腔吸入", "口腔黏膜给药", "滴鼻", "鼻腔喷雾", "鼻饲", "鼻腔吸入", "滴耳", "眼科外用", "涂于眼睑内", "含漱", "含服", "舌下含服", "直肠给药", "直肠塞入", "肛门涂抹", "阴道给药", "阴道塞入", "阴道擦洗"],
      frequencyOption: ["1日1次(qd)", "1日2次(bid)", "1日3次(tid)", "隔日1次(qod)", "必要时(prn)", "1日4次(qid)", "1周1次(qwd)", "隔周1次(qow)", "隔天1次(qod)", "每晚1次(qn)", "立即(st)"],
      usage: ["口服", "外用"],
      kgOption: ["片", "粒", "支", "袋", "枚", "瓶", "滴", "适量", "ug", "mg", "g", "ul", "ml", "IU"],
      SpeeOption: ["g/分钟", "ml/分钟", "ml/小时", "分钟/次", "ug/分钟", "滴/分钟"],
      EditDrugCompositio: [],
      usageOption: ["先煎", "后下", "包煎", "另煎", "烊化", "冲服", "捣碎"],
      imgurl0: require("../../assets/img/state0.png"),
      imgurl1: require("../../assets/img/state1.png"),
      imgurl2: require("../../assets/img/state2.png"),
      isPrint: false,
      printdialogVisible: false,
      printdialogVisibleCY: false,
      printdialogVisibleSY: false,
      printdialogVisibleQT: false,
      esigndialogVisible: false,
      FileUploadUrl: this.Services.Authorization + "/api/File/upload",
      mzPrescriptionData: [],
      auditSignImg: "",
      isAuditfalse: false,
      type: this.$route.query.type,
      PrescriptionDetail:{},
      cancledValue:'',
      cancledialogVisible:false,
      requestOption: ["水煎服；分早晚两次空腹温服", "水煎服；分早晚两次饭前温服", "水煎服；分早晚两次饭后温服", "水煎服；分早晚凉服", "水煎服；1日3次空腹温服", "水煎服；1日3次饭前温服", "水煎服；1日3次饭后温服"],
      allTotalMoney:0,
      othersAllTotalMoney:0,
      servicesDataId:servicesData,
      waitDataTotal:0,
      asstoken:'',
      totalPrice:0,
      changeTable:1,
      // 全局禁用input
      isAllPoint:true,
      timelineList:[],
      nosucecessloading:false
    };
  },
  async mounted() {
    this.asstoken = await this.TokenClient.AccessToken()
    this.getdetailData();
    this.gettWaitData()
  },
  watch: {
    patientFormPre: {
      handler(newValue) {
        this.allTotalMoney = this.othersAllTotalMoney + 0 
        this.patientFormPre.patientFormPreList.map((item, index, arr) => {
          item.mzPrescriptionDetail.map((item1, index1, arr1) => {
            if (item1.tradingPrice) {
              item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0;
              item.isdeliveryPrice = item.isdeliveryPrice ? item.isdeliveryPrice : 0;
              this.allTotalMoney += item.dosage * (item1.weight ? parseFloat(item1.weight) : 0) * item1.tradingPrice;
            }
          });
        });
        this.patientFormPre.patientFormPreCY.map((item, index, arr) => {
          item.mzPrescriptionDetail.map((item1, index1, arr1) => {
            if (item1.tradingPrice) {
              item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0;
              this.allTotalMoney += item1.tradingPrice * item1.defaultDosageList.defaultDosage;
            }
          });
        });
        this.patientFormPre.patientFormPreSY.map((item, index, arr) => {
          item.zucompositionList.map((item1, index1, arr1) => {
            item1.mzPrescriptionDetail.map((item2, index2, arr2) => {
              if (item2.tradingPrice) {
                item2.tradingPrice = item2.tradingPrice ? item2.tradingPrice : 0;
                this.allTotalMoney += item2.tradingPrice * item2.defaultDosageList.defaultDosage;
              }
            });
          });
        });
        this.patientFormPre.mzOtherCharges.map((item, index, arr) => {
          if (item.money) {
            item.money = item.money ? parseFloat(item.money) : 0;
            this.allTotalMoney += item.money;
          }
        });
 
      },
      deep: true,
    }
  },
  methods: {
    getStartAuditPrescriptionData() {
      var _this = this
        _this.drugpatientDomain.getStartAuditPrescription(_this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
          function (data) {
            console.log(data)
          },
          function (error) {
            
          }
        );
    },
    cancleComDialogVisible(){
      this.comDialogVisible = false
      this.compatibilityResultShow = true
    },
    goComtabClick1(){
      var _this = this
      _this.compatibilityResultShow = true
      _this.comDialogVisible = true
      _this.goComtabClick()
    },
    changeDetails(e){
      this.changeTable = e
      if (this.changeTable == 3) {
        this.GePreTimelineData()
      }
    },
    GePreTimelineData(){
       var _this = this;
        _this.drugpatientDomain.GePrescriptionTimelineData(_this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
          function (data) {
            console.log(data)
            _this.timelineList = data.data
          },
          function (error) {
            _this.timelineList = []
          }
        );
    },
    GetUserSign(){
      var _this = this
      _this.patientDomain.GetUserSignData(
        function (data) {
          _this.UserSignImg = data.data
          _this.esigndialogVisible = true;
        },
        function (error) {
          _this.esigndialogVisible = true;
          console.log(error);
        })
    },
    gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    goPrint() {
      this.printdialogVisible = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.print, {
          allowTaint: true,
          taintTest: false,
          useCORS: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4,
        }).then(canvas => {
          // let contentWidth = canvas.width
          // let contentHeight = canvas.height
          // let imgWidth = 595.2
          // let imgheight = 595.2/contentWidth * contentHeight
          const url = canvas.toDataURL("image/jpeg", 1.0);
          printJS({
            printable: url, // 要打印的id
            type: "image",
            style: "@media print{ @page { size: A4; margin: 0mm -1.5mm; mso-header: none; mso-footer: none;  } body{margin:0 5px}}", //去除页眉页脚
          });
           this.printdialogVisible = false;
          // var a = document.createElement('a') // 生成一个a元素
          // var event = new MouseEvent('click') // 创建一个单击事件
          // a.download = name || 'photo' // 设置图片名称
          // a.href = url // 将生成的URL设置为a.href属性
          // a.dispatchEvent(event) // 触发a的单击事件
        });
      });
    },
    goPrintCY() {
      this.printdialogVisibleCY = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.printCY, {
          allowTaint: true,
          taintTest: false,
          useCORS: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4,
        }).then(canvas => {
          // console.log(canvas)
          // let contentWidth = canvas.width
          // let contentHeight = canvas.height
          // let imgWidth = 595.2
          // let imgheight = 595.2/contentWidth * contentHeight
          const url = canvas.toDataURL("image/jpeg", 1.0);
          printJS({
            printable: url, // 要打印的id
            type: "image",
            style: "@media print{ @page { size: A4; margin: 0mm -1.5mm; mso-header: none; mso-footer: none;  } body{margin:0 5px}}", //去除页眉页脚
          });
          this.printdialogVisibleCY = false
        });
      });
    },
    goPrintSY() {
      this.printdialogVisibleSY = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.printSY, {
          allowTaint: true,
          taintTest: false,
          useCORS: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4,
        }).then(canvas => {
          // console.log(canvas)
          // let contentWidth = canvas.width
          // let contentHeight = canvas.height
          // let imgWidth = 595.2
          // let imgheight = 595.2/contentWidth * contentHeight
          const url = canvas.toDataURL("image/jpeg", 1.0);
          printJS({
            printable: url, // 要打印的id
            type: "image",
            style: "@media print{ @page { size: A4; margin: 0mm -1.5mm; mso-header: none; mso-footer: none;  } body{margin:0 5px}}", //去除页眉页脚
          });
          this.printdialogVisibleSY = false;
        });
      });
    },
    goPrintQT() {
      this.printdialogVisibleQT = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.printQT, {
          allowTaint: true,
          taintTest: false,
          useCORS: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4,
        }).then(canvas => {
          const url = canvas.toDataURL("image/jpeg", 1.0);
          printJS({
            printable: url, // 要打印的id
            type: "image",
            imageStyle: `display: block;margin: 0 auto;page-break-after: always;max-width:100%`,
            style: "@media print{ @page { size: A4; margin: 0mm -1.5mm; mso-header: none; mso-footer: none;  } body{margin:0 5px}}", //去除页眉页脚
          });
          this.printdialogVisibleQT = false
        });
      });
    },
    getDrugCompositio() {
      var _this = this;
      _this.drugpatientDomain.GetDrugByOrgInTop10(
        `%20`,
        function (data) {
          // for (let i = 0; i < data.data.length; i++) {
          //   _this.EditDrugCompositio.push({
          //     drugOrgId: data.data[i].id,
          //     drugOrgName: data.data[i].mzDrug.drugName,
          //     drug: data.data[i],
          //   });
          // }
          for (let i = 0; i < data.data.length; i++) {
              if (data.data[i].mzDrug.drugType) {
                if (data.data[i].mzDrug.drugType == 'T') {
                  data.data[i].mzDrug.drugName = data.data[i].mzDrug.drugName + '(统)';
                }
                if (data.data[i].mzDrug.drugType == 'X') {
                  data.data[i].mzDrug.drugName = data.data[i].mzDrug.drugName + '(选)';
                }
                if (data.data[i].mzDrug.drugType == 'J') {
                  data.data[i].mzDrug.drugName = data.data[i].mzDrug.drugName + '(精选)';
                }
              } 
            var label = `${data.data[i].mzDrug.drugName}（${data.data[i].specification}/${data.data[i].specificationUnit}-${data.ata[i].productFactory}）`;
            _this.EditDrugCompositio.push({
              drugOrgId: data.data[i].id,
              drugOrgName: label,
              drugOrgName1: data.data[i].mzDrug.drugName,
              drug: data.data[i],
            });
          }
          // _this.EditDrugCompositio = data.data.forEach(function (item) {
          //     // var label = `[${item.categoryName}]${item.compositionName}`;
          //     var label = `${item.mzDrug.drugName}`;
          //     // if (item.compositionAlias) label += `${item.compositionAlias}`;
          //     return {
          //         drugOrgName: label,
          //         drugOrgId: item.id,
          //         drug:item
          //     };
          // });
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        }
      );
    },
    ConstituteChange(val, item) {
      this.EditDrugCompositio.map(res => {
        if (res.drugOrgId == val) {
          // item.drugOrgName = res.drugOrgName ;
          // item.tradingPrice = res.drug.tradingPrice;
          // item.dosage = res.drug.dosage ? res.drug.dosage : '';
          // item.usage = res.drug.usage ? res.drug.usage : '';
          // item.remark = res.drug.remark ? res.drug.remark : ''
          // item.drugOrgId = res.drugOrgId;
          // item.drugOrgName = res.drugOrgName;
          // item.inventoryNumber = res.drug.inventoryNumber;
          // item.recommendDosage = res.drug.mzDrug.recommendDosage ? res.drug.mzDrug.recommendDosage : '';
          // item.tradingPrice = res.drug.tradingPrice;
          // item.dosage = res.drug.dosage ? res.drug.dosage : "";
          // item.usage = res.drug.usage ? res.drug.usage : "";
          // item.remark = res.drug.remark ? res.drug.remark : "";
          // item.hashKey = res.drug.mzDrug.hashKey ? res.drug.mzDrug.hashKey : "";
          // item.defaultDosageList.defaultDosageUnit = res.drug.mzDrug.defaultDosageUnit;
          // item.dosageList.dosageUnit = res.drug.mzDrug.dosageUnit;
          // item.defaultDosageList.defaultDosage = res.drug.isSeparate ? res.drug.separateNumber : res.drug.mzDrug.defaultDosage;
          // item.defaultDosageList.defaultDosageUnit = res.drug.isSeparate ? res.drug.separateNumberUnit : res.drug.mzDrug.defaultDosageUnit;
          item.drugOrgId = res.drugOrgId;
          // item.drugOrgName = res.drugOrgName1;
          if (res.drug.mzDrug.drugType) {
            if (res.drug.mzDrug.drugType == 'T') {
              item.drugOrgName = res.drugOrgName1 + '(统)';
            }
            if (res.drug.mzDrug.drugType == 'X') {
              item.drugOrgName = res.drugOrgName1 + '(选)';
            }
            if (res.drug.mzDrug.drugType == 'J') {
              item.drugOrgName = res.drugOrgName1 + '(精选)';
            }
            
          } else {
            item.drugOrgName = res.drugOrgName1;
          }
          item.inventoryNumber = res.drug.inventoryNumber;
          item.tradingPrice = res.drug.tradingPrice;
          item.recommendDosage = res.drug.mzDrug.recommendDosage ? res.drug.mzDrug.recommendDosage : "";
          if (res.drug.mzDrug.recommendDosage) {
            var f = res.drug.mzDrug.recommendDosage.split('-')
            var f1 = parseFloat(f[0]) * 1 
            var f2 = parseFloat(f[1]) * 1
            item['recommendDosageMin'] = f1
            item['recommendDosageMax'] = f2
          }
          item.dosage = res.drug.dosage ? res.drug.dosage : "";
          item.usage = res.drug.usage ? res.drug.usage : "";
          item.remark = res.drug.remark ? res.drug.remark : "";
          item.hashKey = res.drug.mzDrug.hashKey ? res.drug.mzDrug.hashKey : "";
          item.drugHashKey = res.drug.mzDrug.hashKey ? res.drug.mzDrug.hashKey : "";
          item.frequency = res.drug.frequency;
          item.dosageList.dosageUnit = res.drug.dosageUnit ? res.drug.dosageUnit : "";
          item.dosageList.dosage = res.drug.dosage ? res.drug.dosage : "";
          item.defaultDosageList.defaultDosage = res.drug.defaultDosage ? res.drug.defaultDosage : "";
          item.defaultDosageList.defaultDosageUnit = res.drug.isSeparate ? res.drug.separateNumberUnit : res.drug.defaultDosageUnit;
        }
      });
    },
    gonaddPre(index) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosage: "",
        dosageUnit: "g",
        usage: "",
        weight: "",
        remark: "",
        decoct:'',
        recommendDosage:''
      };
      this.patientFormPre.patientFormPreList[index].mzPrescriptionDetail.push(row);
    },
    gondeletePre(index, index1) {
      this.patientFormPre.patientFormPreList[index].mzPrescriptionDetail.splice(index1, 1);
    },
    gonaddPreCY(index) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
        groupNumber: "",
        frequency: "",
        usage: "",
        remark: "",
      };
      this.patientFormPre.patientFormPreCY[index].mzPrescriptionDetail.push(row);
    },
    gondeletePreCY(index1, index) {
      this.patientFormPre.patientFormPreCY[index1].mzPrescriptionDetail.splice(index, 1);
    },
    goaddSYzuList(index) {
      let row = {
        prescriptionName: "组",
        mzPrescriptionTitle: [
          {
            usage: "",
            frequency: "",
            drippingSpeedList: {
              drippingSpeed: "",
              drippingSpeedUnit: "",
            },

            startTime: "",
            days: "",
          },
        ],
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
        ],
      };
      this.patientFormPre.patientFormPreSY[index].zucompositionList.push(row);
    },
    godeleteSYzuList(index, index1) {
      this.patientFormPre.patientFormPreSY[index].zucompositionList.splice(index1, 1);
    },
    goaddSYzuDrugList(index, indexComSY1) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        st: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
      };
      this.patientFormPre.patientFormPreSY[index].zucompositionList[indexComSY1].mzPrescriptionDetail.push(row);
    },
    godeletSYzuDrugList(index1, index2, index) {
      this.patientFormPre.patientFormPreSY[index1].zucompositionList[index2].mzPrescriptionDetail.splice(index, 1);
    },
    SelectComposition(val) {
      var _this = this;
      _this.drugpatientDomain.GetDrugByOrgInTop10(
      `${val}`,
        function (data) {
          _this.EditDrugCompositio = data.data.map(function (item) {
              if (item.mzDrug.drugType) {
                if (item.mzDrug.drugType == 'T') {
                  item.mzDrug.drugName = item.mzDrug.drugName + '(统)';
                }
                if (item.mzDrug.drugType == 'X') {
                  item.mzDrug.drugName = item.mzDrug.drugName + '(选)';
                }
                if (item.mzDrug.drugType == 'J') {
                  item.mzDrug.drugName = item.mzDrug.drugName + '(精选)';
                }
              } 
            // var label = `[${item.categoryName}]${item.compositionName}`;
            // var label = `${item.mzDrug.drugName}（库存${item.inventoryNumber}-${item.mzDrug.specification}/${item.mzDrug.specificationUnit}-${item.mzDrug.productFactory}）`;
            var label = `${item.mzDrug.drugName}（${item.mzDrug.specification}/${item.mzDrug.specificationUnit}-${item.mzDrug.productFactory}）`;
            // if (item.compositionAlias) label += `${item.compositionAlias}`;
            return {
              drugOrgName1: item.mzDrug.drugName,
              drugOrgName: label,
              drugOrgId: item.id,
              recommendDosage: item.mzDrug.recommendDosage,
              drug: item,
              inventoryNumber: item.inventoryNumber,
            };
          });
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        } 
      );
    },
    goAddClick() {
      this.getDrugCompositio();
      this.isdrawer = true;
    },
    goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },

    getImageFromUrl(url) {
      console.log(url)
        return this.$axios({
            url,
            method: 'get',
            responseType: 'blob'
        }).then(response => {
          console.log(response)
            const fileReader = new FileReader();
            fileReader.readAsDataURL(response.data);
            return new Promise(resolve => {
                fileReader.onload = () => {
                const base64Data = fileReader.result;
                resolve(base64Data);
            };
        });
      })
      .catch(err=>{
        console.log(err)
      })
    },
    goComDialogVisible(){
      if (!this.Agreement && !this.compatibilityResultShow) {
        this.$message.error('请了解用药提醒并勾选我已知悉')
        return
      }
      this.GetUserSign()
      this.comDialogVisible = false
      this.compatibilityResultShow = true
    },
    getdetailData() {
      var _this = this;
      _this.drugpatientDomain.MZPrescriptionDetail(
        _this.$route.query.id  + '/true',
        function (data) {
          _this.patientDetail = data.data;
          _this.patientDomain.GetPatientDiagnoseDetail(
            data.data.mzPrescriptionOut.patientDiagnoseId,
            function (data) {
              _this.patientDetailAll = data.data;
              _this.patientDomain.GetPatientDetail(data.data.patientId, function (data) {
                _this.patientDetailInfo = data.data;
                if (_this.patientDetail.mzOtherCharges.length > 0) {
                  _this.totalPrice = 0
                  for (let o=0; o<_this.patientDetail.mzOtherCharges.length; o++) {
                    _this.patientDetail.mzOtherCharges[o]['userName'] = _this.patientDetail.mzPrescriptionOut.userName
                    _this.patientDetail.mzOtherCharges[o]['userPhone'] = _this.patientDetail.mzPrescriptionOut.userPhone
                    _this.patientDetail.mzOtherCharges[o]['departmentId'] = _this.patientDetail.mzPrescriptionOut.departmentId ? _this.patientDetail.mzPrescriptionOut.departmentId : 0
                    _this.patientDetail.mzOtherCharges[o]['departmentName'] = _this.patientDetail.mzPrescriptionOut.departmentName
                    _this.totalPrice = _this.totalPrice + _this.patientDetail.mzOtherCharges[o].money
                  }
                  _this.patientFormPre.mzOtherCharges = []
                  for (let i=0; i<_this.patientDetail.mzOtherCharges.length; i++) {
                    if (_this.patientDetail.mzOtherCharges[i].orderType != 5 &&  _this.patientDetail.mzOtherCharges[i].orderType != 8) {
                      _this.patientFormPre.mzOtherCharges.push(_this.patientDetail.mzOtherCharges[i])
                    }
                  }

                  // _this.patientFormPre.mzOtherCharges = _this.patientDetail.mzOtherCharges
                }
                if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP.length > 0) {
                  for (let i = 0; i < _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP.length; i++) {
                    _this.patientFormPre.patientFormPreList.push({
                      id: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].id,
                      usage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].usage,
                      prescriptionName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].prescriptionName,
                      dosageNumber: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosageNumber,
                      dosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosage,
                      frequency: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].frequency,
                      request: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].request,
                      remake: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].remake,
                      type: 0,
                      prescriptionType: 1,
                      waitAuditUserId: _this.patientDetail.mzPrescriptionOut.waitAuditUserId ? _this.patientDetail.mzPrescriptionOut.waitAuditUserId : _this.patientDetail.mzPrescriptionOut.userId,
                      waitAuditUserName: _this.patientDetail.mzPrescriptionOut.waitAuditUserName ? _this.patientDetail.mzPrescriptionOut.waitAuditUserName : _this.patientDetail.mzPrescriptionOut.userName,
                      waitAuditUserPhone: _this.patientDetail.mzPrescriptionOut.auditUserPhone ? _this.patientDetail.mzPrescriptionOut.auditUserPhone : _this.patientDetail.mzPrescriptionOut.userPhone,
                      waitAuditPharmacistId:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistId,
                      waitAuditPharmacistName:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistName,
                      waitAuditPharmacistPhone:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistPhone,
                      mzPrescriptionDetail: [],
                      patientId: _this.patientDetail.mzPrescriptionOut.patientId,
                      patientName: _this.patientDetail.mzPrescriptionOut.patientName,
                      patientPhone: _this.patientDetailInfo.phone,
                      patientDiagnoseId: _this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                      auditDoctorType:_this.patientDetail.mzPrescriptionOut.auditDoctorType,
                      organizationId:_this.patientDetail.mzPrescriptionOut.organizationId,
                      organizationName:_this.patientDetail.mzPrescriptionOut.organizationName,
                      diseaseDiagnose: _this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
                      userId: _this.patientDetail.mzPrescriptionOut.userId,
                      userName: _this.patientDetail.mzPrescriptionOut.userName,
                      userPhone: _this.patientDetail.mzPrescriptionOut.userPhone,
                      departmentId: _this.patientDetail.mzPrescriptionOut.departmentId ? _this.patientDetail.mzPrescriptionOut.departmentId : 0,
                      departmentName: _this.patientDetail.mzPrescriptionOut.departmentName,
                      address: _this.patientDetail.mzPrescriptionOut.address,
                      takePhone: _this.patientDetail.mzPrescriptionOut.takePhone,
                      takeUserName: _this.patientDetail.mzPrescriptionOut.takeUserName,
                      regionId:_this.patientDetail.mzPrescriptionOut.regionId,
                      regionFull:_this.patientDetail.mzPrescriptionOut.regionFull,
                      isSelef: _this.patientDetail.mzPrescriptionOut.isSelef,
                      doctorAdvice:_this.patientDetail.mzPrescriptionOut.doctorAdvice
                    });
                    for (let j = 0; j < _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail.length; j++) {
          
                      for (let m = 0; m < _this.patientFormPre.patientFormPreList.length; m++) {
                        if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].prescriptionName == _this.patientFormPre.patientFormPreList[m].prescriptionName) {
                            if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].recommendDosage) {
                              var f = _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].recommendDosage.split('-')
                              var f1 = parseFloat(f[0]) * 1 
                              var f2 = parseFloat(f[1]) * 1
                              _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j]['recommendDosageMin'] = f1
                              _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j]['recommendDosageMax'] = f2
                            }
                            _this.patientFormPre.patientFormPreList[m].mzPrescriptionDetail.push({
                            drugOrgId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgId ? _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgId : 0,
                            drugOrgName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgName,
                            drugHashKey:_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugHashKey,
                            materialType:_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].materialType,
                            dosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].dosage,
                            dosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].dosageUnit,
                            usage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].usage,
                            weight: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].weight,
                            remark: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].remark,
                            decoct:_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].decoct,
                            recommendDosage:_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].recommendDosage,
                            recommendDosageMin:_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].recommendDosageMin,
                            recommendDosageMax:_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].recommendDosageMax,
                            tradingPrice : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].tradingPrice,
                            drugInventoryNumber : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugInventoryNumber
                          });
                        }
                      }
                    }
                  }
                }
                if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionCY.length > 0) {
                  for (let i = 0; i < _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY.length; i++) {
                    _this.patientFormPre.patientFormPreCY.push({
                      id: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].id,
                      prescriptionName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].prescriptionName,
                      type: 1,
                      prescriptionType: 1,
                      waitAuditUserId: _this.patientDetail.mzPrescriptionOut.waitAuditUserId ? _this.patientDetail.mzPrescriptionOut.waitAuditUserId : _this.patientDetail.mzPrescriptionOut.userId,
                      waitAuditUserName: _this.patientDetail.mzPrescriptionOut.waitAuditUserName ? _this.patientDetail.mzPrescriptionOut.waitAuditUserName : _this.patientDetail.mzPrescriptionOut.userName,
                      waitAuditUserPhone: _this.patientDetail.mzPrescriptionOut.auditUserPhone ? _this.patientDetail.mzPrescriptionOut.auditUserPhone : _this.patientDetail.mzPrescriptionOut.userPhone,
                      waitAuditPharmacistId:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistId,
                      waitAuditPharmacistName:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistName,
                      waitAuditPharmacistPhone:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistPhone,
                      patientId: _this.patientDetail.mzPrescriptionOut.patientId,
                      patientName: _this.patientDetail.mzPrescriptionOut.patientName,
                      patientPhone: _this.patientDetailInfo.phone,
                      patientDiagnoseId: _this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                      auditDoctorType:_this.patientDetail.mzPrescriptionOut.auditDoctorType,
                      organizationId:_this.patientDetail.mzPrescriptionOut.organizationId,
                      organizationName:_this.patientDetail.mzPrescriptionOut.organizationName,
                      diseaseDiagnose: _this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
                      userId: _this.patientDetail.mzPrescriptionOut.userId,
                      userName: _this.patientDetail.mzPrescriptionOut.userName,
                      userPhone: _this.patientDetail.mzPrescriptionOut.userPhone,
                      departmentId: _this.patientDetail.mzPrescriptionOut.departmentId ? _this.patientDetail.mzPrescriptionOut.departmentId : 0,
                      departmentName: _this.patientDetail.mzPrescriptionOut.departmentName,
                      address: _this.patientDetail.mzPrescriptionOut.address,
                      takePhone: _this.patientDetail.mzPrescriptionOut.takePhone,
                      takeUserName: _this.patientDetail.mzPrescriptionOut.takeUserName,
                      regionId:_this.patientDetail.mzPrescriptionOut.regionId,
                      regionFull:_this.patientDetail.mzPrescriptionOut.regionFull,
                      isSelef: _this.patientDetail.mzPrescriptionOut.isSelef,
                      doctorAdvice:_this.patientDetail.mzPrescriptionOut.doctorAdvice,
                      mzPrescriptionDetail: [],
                    });
                    for (let j = 0; j < _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail.length; j++) {
                      for (let m = 0; m < _this.patientFormPre.patientFormPreCY.length; m++) {
                        if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].prescriptionName == _this.patientFormPre.patientFormPreCY[m].prescriptionName) {
                              _this.patientFormPre.patientFormPreCY[m].mzPrescriptionDetail.push({
                              drugOrgId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgId ? _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgId : 0,
                              drugOrgName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgName,
                              drugHashKey:_this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugHashKey,
                              materialType:_this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].materialType,
                              dosageList: {
                                dosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].dosage,
                                dosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].dosageUnit,
                              },
                              defaultDosageList: {
                                defaultDosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].defaultDosage,
                                defaultDosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].defaultDosageUnit,
                              },
                              groupNumber: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].groupNumber,
                              frequency: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].frequency,
                              usage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].usage,
                              tradingPrice : _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].tradingPrice,
                              drugInventoryNumber : _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugInventoryNumber
                            });
                         }
                    
                      }
                    }
                  }
                }
                if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0) {
                  for (let i = 0; i < _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length; i++) {
                    _this.patientFormPre.patientFormPreSY.push({
                      id: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].id,
                      prescriptionName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].prescriptionName,
                      // dosageNumber: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosageNumber,
                      // dosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosage,
                      // frequency: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].frequency,
                      type: 2,
                      prescriptionType: 1,
                      address: _this.patientDetail.mzPrescriptionOut.address,
                      takePhone: _this.patientDetail.mzPrescriptionOut.takePhone,
                      takeUserName: _this.patientDetail.mzPrescriptionOut.takeUserName,
                      regionId:_this.patientDetail.mzPrescriptionOut.regionId,
                      regionFull:_this.patientDetail.mzPrescriptionOut.regionFull,
                      isSelef: _this.patientDetail.mzPrescriptionOut.isSelef,
                      waitAuditUserId: _this.patientDetail.mzPrescriptionOut.waitAuditUserId ? _this.patientDetail.mzPrescriptionOut.waitAuditUserId : _this.patientDetail.mzPrescriptionOut.userId,
                      waitAuditUserName: _this.patientDetail.mzPrescriptionOut.waitAuditUserName ? _this.patientDetail.mzPrescriptionOut.waitAuditUserName : _this.patientDetail.mzPrescriptionOut.userName,
                      waitAuditUserPhone: _this.patientDetail.mzPrescriptionOut.auditUserPhone ? _this.patientDetail.mzPrescriptionOut.auditUserPhone : _this.patientDetail.mzPrescriptionOut.userPhone,
                      waitAuditPharmacistId:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistId,
                      waitAuditPharmacistName:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistName,
                      waitAuditPharmacistPhone:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistPhone,
                      patientId: _this.patientDetail.mzPrescriptionOut.patientId,
                      patientName: _this.patientDetail.mzPrescriptionOut.patientName,
                      patientPhone: _this.patientDetailInfo.phone,
                      patientDiagnoseId: _this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                      auditDoctorType:_this.patientDetail.mzPrescriptionOut.auditDoctorType,
                      organizationId:_this.patientDetail.mzPrescriptionOut.organizationId,
                      organizationName:_this.patientDetail.mzPrescriptionOut.organizationName,
                      diseaseDiagnose: _this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
                      zucompositionList: [],
                      userId: _this.patientDetail.mzPrescriptionOut.userId,
                      userName: _this.patientDetail.mzPrescriptionOut.userName,
                      userPhone: _this.patientDetail.mzPrescriptionOut.userPhone,
                      departmentId: _this.patientDetail.mzPrescriptionOut.departmentId ? _this.patientDetail.mzPrescriptionOut.departmentId : 0,
                      departmentName: _this.patientDetail.mzPrescriptionOut.departmentName,
                      doctorAdvice:_this.patientDetail.mzPrescriptionOut.doctorAdvice
                    });
                    for (let j = 0; j < _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups.length; j++) {

                      for (let m = 0; m < _this.patientFormPre.patientFormPreSY.length; m++) {
                        if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].prescriptionName == _this.patientFormPre.patientFormPreSY[m].prescriptionName) { 
                          _this.patientFormPre.patientFormPreSY[m].zucompositionList.push({
                            mzPrescriptionTitle: [],
                            mzPrescriptionDetail: [],
                          });
                        for (let x = 0; x < _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY.length; x++) {
                          for (let y = 0; y < _this.patientFormPre.patientFormPreSY[m].zucompositionList.length; y++) {
                            _this.patientFormPre.patientFormPreSY[m].zucompositionList[y].mzPrescriptionDetail.push({
                              drugOrgId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgId ? _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgId : 0,
                              drugOrgName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgName,
                              st: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].st,
                              drugHashKey:_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugHashKey,
                              materialType:_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].materialType,
                              dosageList: {
                                dosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].dosage,
                                dosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].dosageUnit,
                              },
                              defaultDosageList: {
                                defaultDosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].defaultDosage,
                                defaultDosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].defaultDosageUnit,
                              },
                              tradingPrice: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].tradingPrice,
                              drugInventoryNumber : _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugInventoryNumber
                            });
                            _this.patientFormPre.patientFormPreSY[m].zucompositionList[y].mzPrescriptionTitle[0] = {
                              frequency: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].frequency,
                              usage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].usage,
                              days: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].days,
                              startTime: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].startTime,
                              drippingSpeedList: {
                                drippingSpeed: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeed,
                                drippingSpeedUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeedUnit,
                              },
                            };
                          }
                        }
                        }
                      }
                    }
                  }
                }
                let id = _this.patientDetail.mzPrescriptionOut.waitAuditUserId == 0 ? _this.patientDetail.mzPrescriptionOut.userId : _this.patientDetail.mzPrescriptionOut.waitAuditUserId
                _this.patientDomain.GetPrintPrescriptionDetail(_this.patientDetail.mzPrescriptionOut.patientDiagnoseId, id, _this.patientDetail.mzPrescriptionOut.waitAuditPharmacistId,function (data) {
                  _this.PrescriptionDetail = data.data
                  if (_this.$route.query.isSrart == 'True') {
                    _this.getStartAuditPrescriptionData()
                  }
                  
                })  
              });
              // _this.patientFormPreSY = data.data.mzPrescriptionSY
              // console.log(_this.patientFormPre.patientFormPreSY)
            },
            function (error) {
              console.log(error);
            }
          );
        },
        function (error) {}
      );
    },
    goBack() {
      this.$router.push({
        name: "PremangePatientList",
      });
    },
    goSuccessEdit(){
      this.isAllPoint = false
    },
    goSuccess(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isAuditfalse = true;
          _this.mzPrescriptionData = [];
          let params = [];
          for (let i = 0; i < _this.patientFormPre.patientFormPreList.length; i++) {
            _this.patientFormPre.patientFormPreList[i].isOk = true;
          }
          for (let i = 0; i < _this.patientFormPre.patientFormPreCY.length; i++) {
            _this.patientFormPre.patientFormPreCY[i].isOk = true;
            for (let j = 0; j < _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail.length; j++) {
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosage = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage;
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageUnit = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit;
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosage = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosage;
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosageUnit = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosageUnit;
            }
          }
          let params2 = [];
          let params1 = [];
          for (let x = 0; x < _this.patientFormPre.patientFormPreSY.length; x++) {
            params2.push(_this.patientFormPre.patientFormPreSY[x]);
          }
          for (let y = 0; y < params2.length; y++) {
            params2[y].mzPrescriptionGroup = params2[y].zucompositionList;
            params1.push({
              mzPrescriptionDetail: params2[y].mzPrescriptionDetail,
              mzPrescriptionGroup: [],
              prescriptionName: params2[y].prescriptionName,
              dosageNumber: params2[y].dosageNumber,
              dosage: params2[y].dosage,
              frequency: params2[y].frequency,
              type: 2,
              id:params2[y].id,
              prescriptionType: 1,
              isSelef: true,
              waitAuditUserId: _this.patientDetail.mzPrescriptionOut.waitAuditUserId ? _this.patientDetail.mzPrescriptionOut.waitAuditUserId : _this.patientDetail.mzPrescriptionOut.userId,
              waitAuditUserName: _this.patientDetail.mzPrescriptionOut.waitAuditUserName ? _this.patientDetail.mzPrescriptionOut.waitAuditUserName : _this.patientDetail.mzPrescriptionOut.userName,
              waitAuditUserPhone: _this.patientDetail.mzPrescriptionOut.auditUserPhone ? _this.patientDetail.mzPrescriptionOut.auditUserPhone : _this.patientDetail.mzPrescriptionOut.userPhone,
              userName: _this.patientDetail.mzPrescriptionOut.userName,
              userPhone: _this.patientDetail.mzPrescriptionOut.userPhone,
              departmentId: _this.patientDetail.mzPrescriptionOut.departmentId ? _this.patientDetail.mzPrescriptionOut.departmentId : 0,
              departmentName: _this.patientDetail.mzPrescriptionOut.departmentName,
              patientId: _this.patientDetail.mzPrescriptionOut.patientId,
              patientName: _this.patientDetail.mzPrescriptionOut.patientName,
              patientPhone: _this.patientDetailInfo.phone,
              patientDiagnoseId: _this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
              auditDoctorType:_this.patientDetail.mzPrescriptionOut.auditDoctorType,
              organizationId:_this.patientDetail.mzPrescriptionOut.organizationId,
              organizationName:_this.patientDetail.mzPrescriptionOut.organizationName,
              diseaseDiagnose: _this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
              waitAuditPharmacistId:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistId,
              waitAuditPharmacistName:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistName,
              waitAuditPharmacistPhone:_this.patientDetail.mzPrescriptionOut.waitAuditPharmacistPhone,
              userId: params2[y].userId,
              // userName: params2[y].userName,
            });
          }
          for (let z = 0; z < params2.length; z++) {
            for (let o = 0; o < params2[z].zucompositionList.length; o++) {
              for (let p = 0; p < params2[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage;
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit;
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage;
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit;
              }
              for (let w = 0; w < params1.length; w++) {
                params1[w].mzPrescriptionGroup.push({
                  usage: params2[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
                  days: params2[z].zucompositionList[o].mzPrescriptionTitle[0].days,
                  drippingSpeed: params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
                  drippingSpeedUnit: params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
                  frequency: params2[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
                  startTime: params2[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
                  mzPrescriptionDetailSY: params2[z].zucompositionList[o].mzPrescriptionDetail,
                });
              }
            }
          }
          for (let i = 0; i < params1.length; i++) {
            params1[i].isOk = true;
          }
          params = _this.patientFormPre.patientFormPreList.concat(_this.patientFormPre.patientFormPreCY).concat(params1);
            if (_this.patientFormPre.patientFormPreList.length > 0) {
            for (let q =0 ; q<_this.patientFormPre.patientFormPreList.length; q++) {
              for (let w =0 ; w<_this.patientFormPre.patientFormPreList[q].mzPrescriptionDetail.length; w++) {
                let res1 = _this.patientFormPre.patientFormPreList[q].mzPrescriptionDetail[w]
                if (res1.weight > res1.inventoryNumber || res1.weight > res1.drugInventoryNumber || (res1.inventoryNumber == '0' && !res1.drugInventoryNumber) ||  (res1.drugInventoryNumber == '0' && !res1.inventoryNumber) ||res1.weight == '0') {
                  _this.$message({
                      message: "请检查药品库存",
                      type: "error",
                    });
                    return ;  
                }
              }
            }
          }
          if (_this.patientFormPre.patientFormPreCY.length > 0) {
            for (let e=0; e<_this.patientFormPre.patientFormPreCY.length; e++) {
              for (let r=0; r<_this.patientFormPre.patientFormPreCY[e].mzPrescriptionDetail.length; r++) {
                let res1 = _this.patientFormPre.patientFormPreCY[e].mzPrescriptionDetail[r] 
                if (res1.defaultDosageList.defaultDosage > res1.inventoryNumber || res1.defaultDosageList.defaultDosage > res1.drugInventoryNumber || (res1.inventoryNumber == '0' && !res1.drugInventoryNumber) ||  (res1.drugInventoryNumber == '0' && !res1.inventoryNumber)) {
                  _this.$message({
                      message: "请检查药品库存",
                      type: "error",
                    });
                    return
                } 
              }
            }
          }
          if (params1.length > 0) {
            for (let t=0; t<params1.length; t++) {
              for (let y=0; y<params1[t].mzPrescriptionGroup.length; y++) {
                for (let p=0; p<params1[t].mzPrescriptionGroup[y].mzPrescriptionDetailSY.length; p++) {
                    let res1 = params1[t].mzPrescriptionGroup[y].mzPrescriptionDetailSY[p] 
                     if ( res1.defaultDosageList.defaultDosage > res1.inventoryNumber || res1.defaultDosageList.defaultDosage > res1.drugInventoryNumber  ||  (res1.inventoryNumber == '0' && !res1.drugInventoryNumber) ||  (res1.drugInventoryNumber == '0' && !res1.inventoryNumber)) {
                    _this.$message({
                        message: "请检查药品库存",
                        type: "error",
                      });
                      return;  
                    }
                }
              }
            }
          }
        // 获取推荐用量不符数据
          if (_this.patientFormPre.patientFormPreList.length > 0) {
            _this.recommendPreList = []
            for (let q = 0; q < _this.patientFormPre.patientFormPreList.length; q++) {
              for (let w = 0; w < _this.patientFormPre.patientFormPreList[q].mzPrescriptionDetail.length; w++) {
                let res1 = _this.patientFormPre.patientFormPreList[q].mzPrescriptionDetail[w];
                if (parseFloat(res1.weight) < res1.recommendDosageMin || parseFloat(res1.weight) > res1.recommendDosageMax) {
                 _this.recommendPreList.push(res1)
                }
              }
            }
          }
          _this.mzPrescriptionData = params;
          _this.nosucecessloading = true
          _this.compatibilityResultShow = false
          _this.goComtabClick()
          setTimeout(()=>{
            _this.nosucecessloading = false
            if(_this.compatibilityList.length > 0 || _this.recommendPreList.length > 0){
                _this.comDialogVisible = true;
            } else {
              _this.GetUserSign()
            }
          },1000)

          // _this.GetUserSign()
        }
      });
      // _this.drugpatientDomain.getAuditMZPrescription(params,
      //     function (data) {
      //         _this.$message({
      //             message: '审核成功',
      //             type: 'success'
      //         });
      //         _this.$router.push({
      //             name:'PremangePatientList'
      //         })
      // },
      // function (err) {
      // console.log(err);
      // });
    },
    handleReset() {
      if (this.$refs.esign) {
        this.$refs.esign.reset();
      }
    },
    goAddCharges() {
      let row = {
        chargeName: "",
        money: "",
        orderType: 3,
        diagnoseResult: this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
        patientDiagnoseId: this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
        userName: this.patientDetail.mzPrescriptionOut.userName,
        userPhone: this.patientDetail.mzPrescriptionOut.userPhone,
        departmentId: this.patientDetail.mzPrescriptionOut.departmentId ? this.patientDetail.mzPrescriptionOut.departmentId : 0,
        departmentName: this.patientDetail.mzPrescriptionOut.departmentName,
      };
      this.patientFormPre.mzOtherCharges.push(row);
    },
    deleteCharges(index) {
      this.patientFormPre.mzOtherCharges.splice(index, 1);
    },
    // 免审核拒绝和开方审核拒绝 
    // // ziji
    // getResubmitPrescriptionData(){
    //     var _this = this
    //     if ( (_this.patientDetail.mzPrescriptionOut.auditStatus == -2 && _this.type == 'ziji') ||  _this.patientDetail.mzPrescriptionOut.auditStatus == -1 ) {

    //     }
    //     _this.drugpatientDomain.getResubmitPrescription(
    //       {
    //         mzPrescription: _this.mzPrescriptionData,
    //         mzOtherCharges:paramsOtner,
    //       },
    //       function (data) {
    //         _this.$message({
    //           message: "审核成功",
    //           type: "success",
    //         });
    //         _this.$router.push({
    //           name: "PremangePatientList",
    //         });
    //       },
    //       function (err) {
    //         console.log(err);
    //       }
    //     );
    // },
    handleGenerate() {
      let _this = this;
       console.log( _this.mzPrescriptionData)
      if (_this.signMent) {
        _this.auditSignImg = _this.UserSignImg
        _this.patientDomain.GetSetUserSign(_this.auditSignImg,function (data) {
           
        }, function (error) {
           
            console.log(error);
        })
        _this.mzPrescriptionData.map((item, index) => {
            item.auditSignImg = _this.auditSignImg;
        });
         _this.esigndialogVisible = false;
        let paramsOtner = []
        for (let i=0; i<_this.patientFormPre.mzOtherCharges.length; i++) {
          if (_this.patientFormPre.mzOtherCharges[i].orderType != 5 && _this.patientFormPre.mzOtherCharges[i].orderType != 8) {
            paramsOtner.push(_this.patientFormPre.mzOtherCharges[i])
          }
        }
        if (_this.isAuditfalse == true) {
              if ( (_this.patientDetail.mzPrescriptionOut.auditStatus == -2 && _this.type == 'ziji') ||  _this.patientDetail.mzPrescriptionOut.auditStatus == -1 ) {
                  if (_this.patientDetail.mzPrescriptionOut.auditStatus == -1) {
                    _this.mzPrescriptionData.map((item, index) => {
                      item.isDoctorRefuse = true;
                    });
                  } else {
                    _this.mzPrescriptionData.map((item, index) => {
                      item.isPharmacistRefuse = true;
                    });
                  }
                    _this.drugpatientDomain.getResubmitPrescription(
                        {
                          mzPrescription: _this.mzPrescriptionData,
                          mzOtherCharges:paramsOtner,
                        },
                        function (data) {
                          _this.$message({
                            message: "审核成功",
                            type: "success",
                          });
                          _this.$router.push({
                            name: "PremangePatientList",
                          });
                        },
                        function (err) {
                          console.log(err);
                        }
                    );
              } else {
                  _this.drugpatientDomain.getAuditMZPrescription(
                    {
                      mzPrescription: _this.mzPrescriptionData,
                      mzOtherCharges:paramsOtner,
                    },
                    function (data) {
                      _this.$message({
                        message: "审核成功",
                        type: "success",
                      });
                      _this.$router.push({
                        name: "PremangePatientList",
                      });
                    },
                    function (err) {
                      console.log(err);
                    }
                  );
              }
        } else {
            _this.drugpatientDomain.getAuditMZPrescription(
              {
                mzPrescription: _this.mzPrescriptionData,
                mzOtherCharges:paramsOtner,
              },
              function (data) {
                _this.$message({
                  message: "审核拒绝",
                  type: "error",
                });
                _this.$router.push({
                  name: "PremangePatientList",
                });
              },
              function (err) {
                console.log(err);
              }
            );
        }
      } else {
      _this.$refs.esign
        .generate()
        .then(res => {
          let randnum = Math.random() * 10000000000000;
          randnum = Math.floor(randnum);
          let fileName = "zhu/" + randnum + ".jpg";
          let esignimgurlFile = _this.dataURLtoFile(res, fileName);
          let dataform = new FormData();
          dataform.append("file", esignimgurlFile);
          _this
            .$axios({
              method: "POST",
              url: _this.FileUploadUrl,
              headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + _this.asstoken },
              data: dataform,
            })
            .then(res => {
              _this.auditSignImg = res.data[0].completeFilePath;
                _this.patientDomain.GetSetUserSign(_this.auditSignImg,function (data) {
                    
                }, function (error) {
                    console.log(error);
                })
              _this.esigndialogVisible = false;
              _this.mzPrescriptionData.map((item, index) => {
                item.auditSignImg = _this.auditSignImg;
              });
              // console.log(_this.patientFormPre.mzOtherCharges)
              let paramsOtner = []
              for (let i=0; i<_this.patientFormPre.mzOtherCharges.length; i++) {
                if (_this.patientFormPre.mzOtherCharges[i].orderType != 5 && _this.patientFormPre.mzOtherCharges[i].orderType != 8) {
                  paramsOtner.push(_this.patientFormPre.mzOtherCharges[i])
                }
              }
              if (_this.isAuditfalse == true) {
                  if ( (_this.patientDetail.mzPrescriptionOut.auditStatus == -2 && _this.type == 'ziji') ||  _this.patientDetail.mzPrescriptionOut.auditStatus == -1 ) {
                    if (_this.patientDetail.mzPrescriptionOut.auditStatus == -1) {
                      _this.mzPrescriptionData.map((item, index) => {
                        item.isDoctorRefuse = true;
                      });
                    } else {
                      _this.mzPrescriptionData.map((item, index) => {
                        item.isPharmacistRefuse = true;
                      });
                    }
                    // _this.mzPrescriptionData.map((item, index) => {
                    //   item.isDoctorRefuse = true;
                    // });
                    _this.drugpatientDomain.getResubmitPrescription(
                        {
                          mzPrescription: _this.mzPrescriptionData,
                          mzOtherCharges:paramsOtner,
                        },
                        function (data) {
                          _this.$message({
                            message: "审核成功",
                            type: "success",
                          });
                          _this.$router.push({
                            name: "PremangePatientList",
                          });
                        },
                        function (err) {
                          console.log(err);
                        }
                    );
              } else {
                   _this.drugpatientDomain.getAuditMZPrescription(
                  {
                    mzPrescription: _this.mzPrescriptionData,
                    mzOtherCharges:paramsOtner,
                  },
                  function (data) {
                    _this.$message({
                      message: "审核成功",
                      type: "success",
                    });
                    _this.$router.push({
                      name: "PremangePatientList",
                    });
                  },
                  function (err) {
                    console.log(err);
                  }
                );
              }
              } else {
                _this.drugpatientDomain.getAuditMZPrescription(
                  {
                    mzPrescription: _this.mzPrescriptionData,
                    mzOtherCharges:paramsOtner,
                  },
                  function (data) {
                    _this.$message({
                      message: "审核拒绝",
                      type: "error",
                    });
                    _this.$router.push({
                      name: "PremangePatientList",
                    });
                  },
                  function (err) {
                    console.log(err);
                  }
                );
              }
            });
        })
        .catch(err => {
          _this.$message.error("请签名之后提交！");
        });
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    gofail() {
      var _this = this;
      // _this.$refs[formName].validate(valid => {})
          _this.isAuditfalse = false;
          _this.cancledValue = ''
          _this.cancledialogVisible = true
          // _this.cancledialogVisible = true;
        
    },
    sureCancleGenerate(){
      var _this = this;
      _this.mzPrescriptionData = [];
          let params = [];
          for (let i = 0; i < _this.patientFormPre.patientFormPreList.length; i++) {
            _this.patientFormPre.patientFormPreList[i].isOk = false;
            _this.patientFormPre.patientFormPreList[i].refusalCause = _this.cancledValue
          }
          for (let i = 0; i < _this.patientFormPre.patientFormPreCY.length; i++) {
            _this.patientFormPre.patientFormPreCY[i].isOk = false;
            _this.patientFormPre.patientFormPreCY[i].refusalCause = _this.cancledValue
            for (let j = 0; j < _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail.length; j++) {
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosage = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage;
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageUnit = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit;
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosage = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosage;
              _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosageUnit = _this.patientFormPre.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosageUnit;
            }
          }
          let params2 = [];
          let params1 = [];
          for (let x = 0; x < _this.patientFormPre.patientFormPreSY.length; x++) {
            params2.push(_this.patientFormPre.patientFormPreSY[x]);
          }
          for (let y = 0; y < params2.length; y++) {
            params2[y].mzPrescriptionGroup = params2[y].zucompositionList;
            params1.push({
              mzPrescriptionDetail: params2[y].mzPrescriptionDetail,
              mzPrescriptionGroup: [],
              prescriptionName: params2[y].prescriptionName,
              dosageNumber: params2[y].dosageNumber,
              dosage: params2[y].dosage,
              frequency: params2[y].frequency,
              type: 2,
              prescriptionType: 1,
              isSelef: true,
              waitAuditUserId: _this.patientDetail.mzPrescriptionOut.waitAuditUserId ? _this.patientDetail.mzPrescriptionOut.waitAuditUserId : _this.patientDetail.mzPrescriptionOut.userName,
              waitAuditUserName: _this.patientDetail.mzPrescriptionOut.waitAuditUserName ? _this.patientDetail.mzPrescriptionOut.waitAuditUserName : _this.patientDetail.mzPrescriptionOut.userName,
              waitAuditUserPhone: _this.patientDetail.mzPrescriptionOut.auditUserPhone ? _this.patientDetail.mzPrescriptionOut.auditUserPhone : _this.patientDetail.mzPrescriptionOut.userPhone,
              patientId: _this.patientDetail.mzPrescriptionOut.patientId,
              patientPhone: _this.patientDetailInfo.phone,
              patientName: _this.patientDetail.mzPrescriptionOut.patientName,
              patientDiagnoseId: _this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
              auditDoctorType:_this.patientDetail.mzPrescriptionOut.auditDoctorType,
              organizationId:_this.patientDetail.mzPrescriptionOut.organizationId,
              organizationName:_this.patientDetail.mzPrescriptionOut.organizationName,
              diseaseDiagnose: _this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
              userName: _this.patientDetail.mzPrescriptionOut.userName,
              userPhone: _this.patientDetail.mzPrescriptionOut.userPhone,
              departmentId: _this.patientDetail.mzPrescriptionOut.departmentId ? _this.patientDetail.mzPrescriptionOut.departmentId : 0,
              departmentName: _this.patientDetail.mzPrescriptionOut.departmentName,
              userId: params2[y].userId,
              // userName: params2[y].userName,
            });
          }
          for (let z = 0; z < params2.length; z++) {
            for (let o = 0; o < params2[z].zucompositionList.length; o++) {
              for (let p = 0; p < params2[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage;
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit;
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage;
                params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit;
              }
              for (let w = 0; w < params1.length; w++) {
                params1[w].mzPrescriptionGroup.push({
                  usage: params2[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
                  days: params2[z].zucompositionList[o].mzPrescriptionTitle[0].days,
                  drippingSpeed: params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
                  drippingSpeedUnit: params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
                  frequency: params2[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
                  startTime: params2[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
                  mzPrescriptionDetailSY: params2[z].zucompositionList[o].mzPrescriptionDetail,
                });
              }
            }
          }
          for (let i = 0; i < params1.length; i++) {
            params1[i].isOk = false;
            params1[i].refusalCause = _this.cancledValue
          }
          params = _this.patientFormPre.patientFormPreList.concat(_this.patientFormPre.patientFormPreCY).concat(params1);
          _this.mzPrescriptionData = params;
          // _this.cancledialogVisible = false;
           _this.GetUserSign()
        // _this.esigndialogVisible = true;
    },
    handleClickTop(e, e1) {
      if (e.label == "患者病历") {
        this.$router.push({
          name: "interrogationIndex",
        });
      }
      if (e.label == "患者列表") {
        this.$router.push({
          name: "intPatientList",
        });
      }
      if (e.label == "个人常用处方") {
        this.$router.push({
          name: "preintPatientList",
        });
      }
      if (e.label == "处方管理") {
        this.$router.push({
          name: "PremangePatientList",
        });
      }
    },
    goComtabClick() {
      var _this = this;
      let peiwuList = [];
      console.log(_this.patientFormPre.patientFormPreList)
      _this.patientFormPre.patientFormPreList.forEach((item, index) => {
        item.mzPrescriptionDetail.forEach((item1, index1) => {
          if (item1.drugOrgId) {
            if (item1.materialType && item1.drugHashKey) {
              peiwuList.push({
                type: item1.materialType,
                // name: item1.drugOrgName,
                // id: item1.drugOrgId,
                key: item1.drugHashKey,
              });
            } else {
              peiwuList.push({
                type: "Material",
                // name: item1.drugOrgName,
                // id: item1.drugOrgId,
                key: item1.drugHashKey,
              });
            }
          }
        });
      });
      _this.patientFormPre.patientFormPreCY.forEach((item, index) => {
        item.mzPrescriptionDetail.forEach((item1, index1) => {
          if (item1.drugOrgId && item1.drugHashKey) {
            peiwuList.push({
              type: "Drug",
              // name: item1.drugOrgName,
              // id: item1.drugOrgId,
              key: item1.drugHashKey ? item1.drugHashKey : item1.preHashKey,
            });
          }
        });
      });
      _this.patientFormPre.patientFormPreSY.forEach((item, index) => {
        item.zucompositionList.forEach((item1, index1) => {
          item1.mzPrescriptionDetail.forEach((item2, index2) => {
            if (item2.drugOrgId && item2.drugHashKey) {
              peiwuList.push({
                type: "Drug",
                // name: item2.drugOrgName,
                // id: item2.drugOrgId,
                key: item2.drugHashKey ? item2.drugHashKey : item1.preHashKey,
              });
            }
          });
        });
      });
      if (peiwuList.length > 0) {
        peiwuList.forEach((res, index) => {
          switch (res.type) {
            case "Drug": //药品
              res.type = 1;
              break;
            case "Material": //中药
              res.type = 4;
              break;
            case "HerbalPrescription": //中药
              res.type = 2;
              break;
            case "FoodMaterialPrescription": //中药
              res.type = 3;
              break;
            case "HealthyDiet": //中药
              res.type = 5;
              break;
          }
        });
      }
      const uniqueArray = peiwuList.filter((item, index, self) => self.findIndex((t) => t.key === item.key) === index);
      // if (uniqueArray.length > 2) {
      //     _this.compatibility(uniqueArray)
      // } else  {
      //     _this.$message({
      //         message: '配伍数量不超过两个',
      //         type: 'error'
      //     });
      // }
      _this.compatibility(uniqueArray);
    },
    compatibility(peiwuList) {
      var _this = this;
      _this.types = [0, 1, 2, 3, 4, 5, 6];
      _this.compatibilityList = [];
      _this.similarList = [];
      _this.instructions = [];
      _this.dietTotal = 0;
      _this.avoidTotal = 0;
      _this.total = 0;
      _this.crowdTotal = 0;
      _this.compatibilityTotal = 0;
      _this.similarTotal = 0;
      let params = {};
      params.items = peiwuList;
      (params.types = _this.types),
        _this.compatibilityDomain.MZCompatibilitys(
          params,
          function (data) {
            _this.oldName = getCompatibilityList()
              .filter((x) => x.state === "1")
              .map((x) => x.value)
              .join("---");
              if (!_this.compatibilityResultShow) {
                _this.compatibilityList = data.data.compatibility.filter((x) => x.compatibilityType !== 7 && x.result);
              } else {
                _this.compatibilityList = data.data.compatibility.filter((x) => x.compatibilityType !== 7);
              }
            _this.similarList = data.data.similar;
            _this.instructions = data.data.instructions;
            _this.types = data.data.types;
            _this.totalprocess();
          },
          function (error) {
            console.log("配伍请求异常:", error);
          }
        );
    },
    totalprocess() {
      this.similarTotal = this.types.indexOf(0) != -1 && this.similarList != null ? this.similarList.length : 0;
      this.compatibilityTotal = this.types.indexOf(0) != -1 ? this.compatibilityList.length : 0;
      this.crowdTotal = this.getNumByType(this.instructions, 3) + this.getNumByType(this.instructions, 4) + this.getNumByType(this.instructions, 5) + this.getNumByType(this.instructions, 6);

      this.dietTotal = this.getNumByType(this.instructions, 2);
      this.avoidTotal = this.getNumByType(this.instructions, 1);
      this.total = this.avoidTotal + this.dietTotal + this.crowdTotal + this.compatibilityTotal + this.similarTotal;
    },
    similarhtmlRaw(item) {
      var info = "";
      if (item.sourceDrugCategory != item.targetDrugCategory) {
        // var reg = new RegExp(item.sourceDrugComposition.replace(/\s/g, "").split("").join("|"), "ig");
        // var reg1=new RegExp(',','g')
        // console.log(item.targetDrugComposition.match(reg).join().replace(reg1,''))
        info = `${item.sourceDrugName}:${item.sourceDrugComposition}<br> ${item.targetDrugName}:${item.targetDrugComposition} <br> ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `;
      } else {
        info = `${item.sourceDrugName} 与 ${item.targetDrugName} 同属于 ${item.sourceDrugCategory}(${item.sourceDrugCategoryName})`;
      }
      // info=`${item.sourceDrugName}:${item.sourceDrugComposition}<br>
      //     ${item.targetDrugName}:${item.targetDrugComposition} <br>
      //     ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `
      return info;
    },
    namehtmlraw(item) {
      return namehtmlraw(item);
    },
    //tip分组
    tipGroup(list) {
      return tipGroup(list);
    },
    //内容HTML转换
    contenthtmlRaw(item, isdiet = false) {
      return contenthtmlRaw(item, isdiet);
    },
    //饮食禁忌 分组
    dietGroup(list) {
      return dietGroup(list);
    },
    //特殊人群列表
    getcrowdList() {
      return getcrowdList(this.instructions);
    },
    showCourseInfoList(e) {
      const target = e.currentTarget;
      // 寻找父元素的兄弟元素进行显示与隐藏控制
      $(target).siblings().toggle();
      // 切换样式-图标
      $(target).find("i").toggleClass("el-icon-arrow-down").addClass("el-icon-arrow-up");
      if ($(target).siblings().is(":hidden")) {
        $(target).find(".show-text").html("展开");
      } else {
        $(target).find(".show-text").html("收起");
      }
    },
    // 配伍
    getNumByType(array, type) {
      let num = 0;
      if (array.filter((i) => i.type == type).length > 0) {
        num = array.filter((i) => i.type == type)[0].items.length;
      }
      return num;
    },
  },
};
</script>
<style scoped>
.imgBox {
  position: absolute;
  right: 0;
  top: 0;
}
.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
  /* width: 95%; */
  margin-left: 2.5%;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
  background: #f0f2f5;
}
.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* min-height: 750px; */
  overflow: auto;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.noflex-l-s1 {
  display: flex;
  align-items: center;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  /* min-height: 750px; */
  background-color: #ffffff;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 0 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.titleTopCenterCenterTopLine {
  border-bottom: 3px solid #f0f2f5;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Content1Title {
  padding: 20px 40px;
  padding-left: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0;
  text-align: left;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.ageInput {
  width: 80%;
}
.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.greenColor {
  color: #06add7;
}
.titleSize16 {
  font-size: 16px;
}
.titleSize14 {
  font-size: 14px;
}
.titleSize22 {
  font-size: 22px;
}
.tagEdit {
  margin-left: 60%;
  width: 30%;
  height: 40px;
  border: 1px solid #00afb5;
  color: #00afb5;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
}
.patientimeBox {
  width: 24%;
  padding: 24px 30px;
  background: #fafafa;
  border: 1px solid #cfe5e6;
  border-radius: 8px;
  text-align: center;
  margin-right: 1%;
  cursor: pointer;
}
.patientimeBtom {
  text-align: left;
  margin-top: 15px;
}
.titleTopLeftTable {
  /* width: 50%; */
  padding: 20px 0px;
  font-size: 16px;
  border-bottom: 2px solid #e1e3e6;
}
.titleTopLeftTableC {
  color: #06add7;
}
.notitleTopLeftTableC {
  color: #999999;
}
.titleTopLeftTableTag {
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #07abda, #01c4af);
  border-radius: 50%;
  margin-right: 5px;
}
.titleTopLeftTableTag1 {
  width: 4px;
  height: 4px;
  background: #f82a2a;
  border-radius: 50%;
  margin-right: 5px;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.avatar-uploader-icon {
  font-size: 22px;
  color: #00afb5;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #b9dcdd;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #f3f9f9;
}
.avatar-uploader-icon:hover {
  font-size: 22px;
  color: #00afb5;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-color: #409eff;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.PrintBox {
  width: 600px;
  background: #ffffff;
  font-size: 14px;
  padding: 20px;
}
.PrintBoxTitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
.PrintBoxTitle1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PrintBoxTitle2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.PrintBoxTitle2 div {
  margin-right: 40px;
}
.PrintBoxTitleLine {
  width: 100%;
  height: 1px;
  background: #000000;
  margin: 10px 0;
}
.PrintBoxTitleLineD {
  margin: 20px 0;
  border-bottom: 1px dashed #999999;
}
.infoWarn {
  position: absolute;
  bottom: -30px;
  left: 0;
}
.infoWarn1 {
  position: absolute;
  bottom: 0px;
  right: 5px;
}
::v-deep .el-form-item__content {
  padding-bottom: 20px;
}
::v-deep .el-form-item {
  margin-bottom: 0px;
}

.el-page-header {
  line-height: 73px;
  /* padding-left: 20px; */
}
.numDiv2 {
    padding: 15px;
    background: #fff7f7;
    border: 1px solid #f4d3d3;
    border-radius: 8px;
    margin: 20px 0;
    }
.Content3TitleBox {
  margin-left: 15px;
  margin-right: 10%;
}
.Content3TitleBoxRight {
  margin: 15px 0;
  background: #fff7f7;
  border: 1px solid #f4d3d3;
  border-radius: 6px;
  text-align: right;
  padding: 10px 30px;
}
.Content3TitleBoxRightSpan {
  font-size: 24px;
  color: #ee1515;
}
.ZydrugOrgNameBox1 {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  border-bottom: 1px dashed #999999;
}
.ZydrugOrgNameBox1 {
  width: 30%;

}
.ZydrugOrgNameBox1  span{
  text-align: left;
}
.ZydrugLineBox {
  display: flex;
  flex-direction: column;
  /* justify-items: center; */
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}
.ZydrugBtoBox {
  width: 100%;
  height: 120px;
}
.topDetailBox {
  background: #F0F2F5;
  border-radius: 4px;
  padding: 20px;
}
.discriminatoryAnalysisBox {
  background: #E2E2E2;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  color: #333333;
}
.flex-l {
  display: flex;
  align-items: center;
}
.pei-bottom {
  background: #ffffff;
  padding: 10px;
  /* margin-top: 20px; */
}

.tab-tilte {}

.tab-tilte li {
  float: left;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-right: 15px;
}

.tab-tilte li em {
  font-style: normal;
  margin: 0 5px;
  font-size: 14px;
}

/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte li.greenbg {
  border: 1px solid #1fb7a6 !important;
}

.tab-tilte li.greenbg em {
  color: #1fb7a6 !important;
}

.tab-tilte li.active {
  border: 1px solid #f46e6e;
}

.tab-tilte li.active em {
  color: #f46e6e;
}

.tab-tilte li i {
  color: #f46e6e;
}

.tab-tilte li.active span {
  color: #f46e6e;
}

.tab-tilte li.greenbg i {
  color: #1fb7a6;
}

.tab-tilte li.greenbg span {
  color: #1fb7a6;
}

.tab-content {
  margin-top: 5px;
}

.peitab-tit {
  position: relative;
  /* border-top: 1px solid #e5e5e5; */
  padding: 20px 0px;
}

.peitab-tit h3 {
  display: inline-block;
  width: 830px;
  font-weight: normal;
  color: #333333;
  font-size: 18px;
}

.peitab-tit h3 span {
  color: #e94343;
}

.peitab-tit .show-more {
  display: inline-block;
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}

.peitab-mes {
  display: none;
  width: 830px;
  padding: 10px 25px;
}

.peitab-mes p {
  line-height: 30px;
  color: #666666;
}

.peitab-mes .linkDetail {
  display: inline-block;
  color: #01c2ac;
  cursor: pointer;
}

.peitab-mes .linkDetail:hover {
  text-decoration: underline;
}
</style>

 